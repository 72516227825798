import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { functions } from '../config/FirebaseConfig';

interface GenericCallableResponse {
    status: number;
    message?: string;
}

export interface Customer {
    id?: string;
    name: string;
    email: string;
    active: boolean;
    logo_light: any;
    logo_dark: any;
    question_set_config: {
        bot_id: string;
        version_label: string;
        bot_name: string;
    };
    sms_config: {
        sender_label: string;
    };
}

export interface CustomerPayload {
    id?: string;
    name: string;
    email: string;
    active: boolean;
}

export const createNewCustomer = async (
    data: CustomerPayload,
): Promise<HttpsCallableResult<GenericCallableResponse>> => {
    return httpsCallable<CustomerPayload, GenericCallableResponse>(
        functions,
        'customers-create',
    )(data);
};

export const removeCustomer = async (
    customerId: string,
): Promise<HttpsCallableResult<GenericCallableResponse>> => {
    return httpsCallable<{ customer_id: string }, GenericCallableResponse>(
        functions,
        'customers-expunge',
    )({ customer_id: customerId });
};
