import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Assessment, StructuredTranscript } from '../../../types';
import { pdfCommonStyle, pdfStylingVars } from '../../pdfStyle';
import { Header, HorizontalLine, PatientInfoHeader } from '../../components';
import {
    ConditionHistory,
    ContactInformation,
    Questionnaire,
    Miscellaneous,
    FullTranscript,
} from './components';

interface Data {
    patient: Assessment;
    transcript: StructuredTranscript[];
}

export default function PdfPreAssessmentReport(data: Data): TDocumentDefinitions {
    const { patient, transcript } = data;
    const contactDetails = transcript.shift()?.content[0].body[0];
    const transcriptConversation = transcript.pop()?.content;

    // Style
    const { pageMargins, spacingLarge } = pdfStylingVars.spacing;

    return {
        content: [
            PatientInfoHeader({
                reportTitle: 'Health Information\nShare Report Summary',
                patient,
            }),
            HorizontalLine({ vMargin: spacingLarge }),
            ContactInformation({ contactDetails }),
            Questionnaire(),
            transcript.map(ConditionHistory),
            // This is static content for future-proofing
            Miscellaneous({
                content: [
                    {
                        header: '08.01: Additional Comments',
                        body: ['No'],
                    },
                ],
            }),
            FullTranscript({ content: transcriptConversation }),
        ],

        header: (pageNum) => pageNum !== 1
            ? Header(
                'Health Information Share Report: ' +
                `${patient.first_name} ${patient.last_name}`
            )
            : null,

        styles: pdfCommonStyle,

        defaultStyle: pdfCommonStyle.body1,

        pageMargins,

        info: {
            title: 'Health Information Share Report: ' +
                `${patient.first_name} ${patient.last_name}`,
            author: 'EQL Ltd',
        }
    }
}
