import React from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    Stack,
    Paper,
    Divider,
    Tooltip,
    IconButton,
    ListItemIcon,
    CircularProgress,
    Typography,
    Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    getDocs,
    collection,
    Timestamp,
    updateDoc,
    doc,
    limit,
    query,
    orderBy,
    where,
} from 'firebase/firestore';
import { db } from '../config/FirebaseConfig';
import { ArchiveOutlined, SyncOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import CallableServices from '../services/CallableServices';
import useConfirm from '../hooks/useConfirm';

type qNode = {
    id: string;
    revision_number: number;
    draft_mode: boolean;
    number_of_nodes: number;
    created_at: Timestamp;
    synced_by: string;
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const COLLECTION_NAME = 'questionnaires';

const Questionnaire = () => {
    const [qItems, setqItems] = React.useState<Array<qNode>>([]);
    const [executingSync, setExecutingSync] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { isConfirmed } = useConfirm();

    const fetchQuestionnaire = async () => {
        const ref = collection(db, 'questionnaires');
        const refQuery = query(
            ref,
            where('is_archived', '==', false),
            orderBy('created_at', 'asc'),
            limit(100),
        );
        const results = await getDocs(refQuery);
        const temp: qNode[] = [];
        results.forEach((result) => {
            const data = { ...result.data(), id: result.id } as qNode;
            temp.push(data);
        });
        setqItems(temp);
    };

    const handleToggle = async (value: qNode) => {
        const currentVersion = qItems.find((item) => item.draft_mode === false);
        const confirm = await isConfirmed(
            `Are you sure you want to change the active question set to ${value.id}? 
            This action will instruct the chat flow to use ${value.id}`,
        );
        if (!confirm) {
            return;
        }
        const promises: Promise<any>[] = [];
        promises.push(
            updateDoc(doc(db, COLLECTION_NAME, value.id), {
                draft_mode: false,
            }),
        );
        if (currentVersion) {
            promises.push(
                updateDoc(doc(db, COLLECTION_NAME, currentVersion.id), {
                    draft_mode: true,
                }),
            );
        }
        await Promise.all(promises);
        await fetchQuestionnaire();
    };

    const handleDataSync = async () => {
        const confirm = await isConfirmed(
            'Are you sure you want to sync the current spreadsheet?',
        );
        if (!confirm) {
            return;
        }
        setExecutingSync(true);
        const res = await CallableServices.syncSpreadsheet(checked);
        const { status, message } = res.data;
        setExecutingSync(false);
        if (status !== 200) {
            enqueueSnackbar(`Data sync failed ${message}`, {
                variant: 'error',
            });
            return;
        }
        await fetchQuestionnaire();
        enqueueSnackbar(`Data synced successfully`, { variant: 'success' });
    };

    const handleArchive = async (qItem: { id: string }) => {
        const confirm = await isConfirmed(
            'Are you sure you want to archive this questionnaire?',
        );
        if (!confirm) {
            return;
        }
        const ref = doc(db, `questionnaires/${qItem.id}`);
        await updateDoc(ref, { is_archived: true });
        await fetchQuestionnaire();
        enqueueSnackbar('Questionnaire archived!', { variant: 'info' });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    React.useEffect(() => {
        (async () => {
            await fetchQuestionnaire();
        })();
    }, []);

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <Box mt={4} mb={4}>
                <Stack direction="row" spacing={2}>
                    <Item>
                        {executingSync ? (
                            <CircularProgress size={22} color="info" />
                        ) : (
                            <Tooltip title="Synchronise questionnaire spreadsheet">
                                <IconButton onClick={() => handleDataSync()}>
                                    <SyncOutlined
                                        fontSize="large"
                                        color="info"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Box>
                            <Typography variant="overline">
                                Update Medical and Condition List
                            </Typography>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Typography>Off</Typography>
                                <Switch
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography>On</Typography>
                            </Stack>
                        </Box>
                    </Item>
                </Stack>
            </Box>
            <List
                dense
                sx={{
                    width: '100%',
                    // maxWidth: 360,
                    bgcolor: 'background.paper',
                    height: window.innerHeight - 300,
                    overflow: 'scroll',
                }}
            >
                {qItems.map((item) => (
                    <React.Fragment key={item.id}>
                        <ListItem
                            secondaryAction={
                                <Checkbox
                                    edge="end"
                                    onChange={() => handleToggle(item)}
                                    checked={!item.draft_mode}
                                    inputProps={{
                                        'aria-labelledby': `checkbox-list-secondary-label-${item.id}`,
                                    }}
                                />
                            }
                        >
                            <ListItemIcon>
                                <Tooltip title="Archive questionnaire">
                                    <IconButton
                                        onClick={() => handleArchive(item)}
                                    >
                                        <ArchiveOutlined />
                                    </IconButton>
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText
                                primary={item.id}
                                secondary={`Uploaded on ${item.created_at
                                    .toDate()
                                    .toLocaleDateString()} by ${
                                    item.synced_by
                                }`}
                            />
                        </ListItem>
                        <Divider variant="fullWidth" />
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
};

export default Questionnaire;
