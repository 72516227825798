import React from 'react';
import {
    Box,
    Stack,
    Tooltip,
    Backdrop,
    CircularProgress,
    createTheme,
    Button,
} from '@mui/material';
import MaterialTable from 'material-table';
import { ThemeProvider } from '@mui/styles';
import { Delete, PersonAdd } from '@mui/icons-material';
import AddUserDialog from './AddUserDialog';
import { AdminUser } from '../../types';
import CallableServices from '../../services/CallableServices';
import { useSnackbar } from 'notistack';
import useConfirm from '../../hooks/useConfirm';
import AdminUserRepo from '../../core/repositories/AdminUserRepo';

export const InternalViewForUsers = () => {
    const [newUserDialogOpen, setNewUserDialogOpen] = React.useState(false);
    const [data, setData] = React.useState<AdminUser[]>([]);
    const [loading, setLoading] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const { isConfirmed } = useConfirm();

    const getUsers = async () => {
        setLoading(true);
        const adminRepo = new AdminUserRepo();
        const allUsers = await adminRepo.getAllAdminUsers();
        if (!allUsers) {
            setData([]);
            setLoading(false);
            return;
        }
        setData(allUsers);
        setLoading(false);
    };

    const deleteUser = async (rowData: AdminUser) => {
        if (!rowData || !rowData.id) {
            return;
        }
        const confirm = await isConfirmed(
            'Are you sure you want to delete this user?',
        );
        if (!confirm) {
            return;
        }
        setLoading(true);
        const response = await CallableServices.deleteAdminUser(rowData.id);
        const { status } = response.data;
        if (status !== 200) {
            enqueueSnackbar(response.data.message, { variant: 'error' });
            return;
        }
        enqueueSnackbar('User removed', { variant: 'success' });
        getUsers();
    };

    React.useEffect(() => {
        getUsers();
    }, []);

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <Box sx={{ mt: 4, mb: 4 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Box />
                    <Tooltip title="Add admin user for a customer">
                        <Button
                            startIcon={<PersonAdd />}
                            variant="outlined"
                            onClick={() => {
                                setNewUserDialogOpen(true);
                            }}
                        >
                            Add Admin User
                        </Button>
                    </Tooltip>
                </Stack>
            </Box>
            <ThemeProvider theme={createTheme()}>
                <MaterialTable
                    title="User Management"
                    columns={[
                        { field: 'first_name', title: 'First Name' },
                        { field: 'last_name', title: 'Last Name' },
                        { field: 'email', title: 'Email' },
                        { field: 'role_type', title: 'Role' },
                        { field: 'customer_name', title: 'Customer' },
                    ]}
                    data={data}
                    options={{
                        searchFieldVariant: 'outlined',
                        pageSize: 20,
                        pageSizeOptions: [20, 40, 60],
                        actionsCellStyle: {
                            minWidth: '88px',
                            paddingLeft: '24px',
                            paddingRight: '24px',
                        },
                    }}
                    actions={[
                        {
                            icon: () => <Delete color="error" />,
                            tooltip: 'Remove',
                            onClick: (event, rowData) => {
                                deleteUser(rowData as AdminUser);
                            },
                        },
                    ]}
                />
            </ThemeProvider>
            <AddUserDialog
                open={newUserDialogOpen}
                onClose={() => {
                    setNewUserDialogOpen(false);
                    getUsers();
                }}
            />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};
