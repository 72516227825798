import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useConfirm from '../hooks/useConfirm';

export default function ConfirmDialog() {
    const { prompt = '', isOpen = false, proceed, cancel } = useConfirm();

    return isOpen ? (
        <Dialog
            open={isOpen}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {'CONFIRM YOUR ACTION'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {prompt}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={cancel}>
                    Cancel
                </Button>
                <Button onClick={proceed} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;
}
