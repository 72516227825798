import { Typography } from '@mui/material';

const CopyrightComponent = (props: any) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {'Copyright © EQL Limited '}
            {new Date().getFullYear()}
            {'.'}
            {`v${process.env.REACT_APP_VERSION}`}
        </Typography>
    );
};

export default CopyrightComponent;
