import CustomerListIcon from '@mui/icons-material/BusinessOutlined';
import LogsIcon from '@mui/icons-material/HistoryEduOutlined';
import UsersIcon from '@mui/icons-material/PeopleAltOutlined';
import CustomerAdminIcon from '@mui/icons-material/PersonPinCircleRounded';
import StorageIcon from '@mui/icons-material/StorageOutlined';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import ListAltOutlined from '@mui/icons-material/ListAltOutlined';
import { DashboardCustomize } from '@mui/icons-material';

const getSystemAdminNavigationItems = () => {
    return [
        {
            title: 'Customers',
            icon: CustomerListIcon,
            path: '/dashboard',
        },
        {
            title: 'System',
            path: 'system',
            icon: UsersIcon,
        },
        {
            title: 'Admins',
            path: 'user-management',
            icon: CustomerAdminIcon,
        },
        { title: 'Reports (PIP)', icon: ListAltOutlined, path: 'reports/pip' },
        {
            title: 'Reports (CHDA)',
            icon: ListAltOutlined,
            path: 'reports/chda',
        },
        { title: 'Message Centre (PIP)', icon: SmsOutlined, path: 'messages' },
        { title: 'Message Centre (CHDA)', icon: SmsOutlined, path: 'sms' },
        {
            title: 'Questionnaire (CHDA)',
            icon: StorageIcon,
            path: 'store',
        },
        {
            title: 'Logs',
            icon: LogsIcon,
            path: 'logs',
        },
        {
            title: 'Management Information',
            icon: DashboardCustomize,
            path: 'management-information',
        },
    ];
};

export const getSystemUserNavigationItems = () => {
    return [
        {
            title: 'Customers',
            icon: CustomerListIcon,
            path: '/dashboard',
        },
        {
            title: 'Admins',
            path: 'user-management',
            icon: CustomerAdminIcon,
        },
        { title: 'Reports (PIP)', icon: ListAltOutlined, path: 'reports/pip' },
        {
            title: 'Reports (CHDA)',
            icon: ListAltOutlined,
            path: 'reports/chda',
        },
        { title: 'Message Centre (PIP)', icon: SmsOutlined, path: 'messages' },
        { title: 'Message Centre (CHDA)', icon: SmsOutlined, path: 'sms' },
        {
            title: 'Questionnaire (CHDA)',
            icon: StorageIcon,
            path: 'store',
        },
        {
            title: 'Logs',
            icon: LogsIcon,
            path: 'logs',
        },
        {
            title: 'Management Information',
            icon: DashboardCustomize,
            path: 'management-information',
        },
    ];
};

export default getSystemAdminNavigationItems;
