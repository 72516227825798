import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { PdfPreAssessmentReport } from '../pdf/documents';
import pdfMake from 'pdfmake/build/pdfmake';
import { Assessment, StructuredTranscript } from '../types';
import PdfViewer from '../components/PdfViewer';

interface Props {
    open: boolean;
    patient: Assessment | null;
    transcript?: StructuredTranscript[];
    onClose: () => void;
}

const TranscriptViewer = (props: Props) => {
    const { open, transcript, patient, onClose } = props;

    const [pdfDataUrl, setPdfDataUrl] = useState<string | null>(null);

    useEffect(() => {
        if (!transcript || !patient || !open) {
            setPdfDataUrl(null);
            return;
        }

        const doc = PdfPreAssessmentReport({ patient, transcript });
        pdfMake.createPdf(doc).getDataUrl(setPdfDataUrl);
    }, [open, patient, transcript]);

    return (
        <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose}>
            <DialogTitle>Health Information Share Report</DialogTitle>
            <DialogContent>
                <PdfViewer file={pdfDataUrl} patient={patient} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TranscriptViewer;
