import React from 'react';
import { Container, Divider, Tabs, Tab } from '@mui/material';
import ContactsUploadContainer from './ContactsUploadContainer';
import DispatchSmsContainer from './DispatchSmsContainer';
import SmsContactsList from './SmsContactsList';
import { useAuth } from '../../hooks/useAuth';
import SettingsView from './SettingsView';

const SendSMS = () => {
    const [value, setValue] = React.useState(0);

    const auth = useAuth();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Container maxWidth="xl">
            <Divider>Message Centre</Divider>
            <Tabs
                onChange={handleChange}
                value={value}
                indicatorColor="secondary"
                aria-label="recipient tabs"
            >
                <Tab label="Invite Log" />
                <Tab label="Upload" />
                {auth?.is_internal && auth.is_super && <Tab label="Dispatch" />}
                {auth?.is_internal && auth.is_super && <Tab label="Settings" />}
            </Tabs>
            {value === 0 && <SmsContactsList />}
            {value === 1 && (
                <ContactsUploadContainer onSmsDispatched={() => setValue(0)} />
            )}
            {value === 2 && auth.is_internal && auth.is_super && (
                <DispatchSmsContainer />
            )}
            {value === 3 && auth.is_internal && auth.is_super && (
                <SettingsView />
            )}
        </Container>
    );
};

export default SendSMS;
