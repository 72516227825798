import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container, CssBaseline, Toolbar } from '@mui/material';
import SideDrawer from './components/SideDrawer';
import TopBar from './components/TopBar';
import CopyrightComponent from '../components/CopyrightComponent';

const DashboardLayout: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'stretch',
                background: 'yellow',
            }}
        >
            <CssBaseline />
            <TopBar
                open={drawerOpen}
                onToggleDrawer={() => setDrawerOpen(!drawerOpen)}
            />
            <SideDrawer
                open={drawerOpen}
                onToggleDrawer={() => setDrawerOpen(!drawerOpen)}
            />
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    height: '100vh',
                    overflow: 'auto',
                    flex: 2,
                }}
            >
                <Toolbar />
                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                    <Outlet />
                </Container>
                <CopyrightComponent />
            </Box>
        </Box>
    );
};

export default DashboardLayout;
