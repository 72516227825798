import { Box, Divider, IconButton } from '@mui/material';
import {
    ArrowBack,
    ArrowForward,
    Print,
    ZoomIn,
    ZoomOut,
} from '@mui/icons-material';

interface Props {
    numPages: number | null;
    pageNumber: number;
    scale: number;
    nextPage(): void;
    previousPage(): void;
    zoomIn(): void;
    zoomOut(): void;
    print: (() => void) | null;
}

export default function Toolbar(props: Props) {
    const {
        numPages,
        pageNumber,
        scale,
        nextPage,
        previousPage,
        zoomIn,
        zoomOut,
        print
    } = props;

    return (
        <Box
            sx={{
                position: 'sticky',
                top: 5,
                left: 0,
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                bgcolor: 'background.paper',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                boxShadow: '0 2px 20px -4px #0000001a',
                color: 'text.secondary',
                '& p': { m: 1.5 },
                '& hr': { mx: 0.5 },
            }}
        >
            <IconButton
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
                aria-label="Previous Page"
            >
                <ArrowBack />
            </IconButton>
            <IconButton
                type="button"
                disabled={pageNumber >= numPages!}
                onClick={nextPage}
                aria-label="Next Page"
            >
                <ArrowForward />
            </IconButton>
            <p>
                {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
            </p>
            <Divider orientation="vertical" variant="middle" flexItem />
            <IconButton
                type="button"
                disabled={scale === 1}
                onClick={zoomOut}
                aria-label="Zoom Out"
            >
                <ZoomOut />
            </IconButton>
            <IconButton
                type="button"
                disabled={scale === 4}
                onClick={zoomIn}
                aria-label="Zoom In"
            >
                <ZoomIn />
            </IconButton>
            {print && (
                <>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                    />
                    <IconButton
                        type="button"
                        disabled={numPages === null}
                        onClick={print}
                        aria-label="Print PDF Document"
                    >
                        <Print />
                    </IconButton>
                </>
            )}
        </Box>
    );
}
