import React from 'react';
import {
    Box,
    Stack,
    Tooltip,
    IconButton,
    createTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    Backdrop,
    CircularProgress,
    Button,
} from '@mui/material';
import { AddBusiness } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import MaterialTable from 'material-table';
import { ThemeProvider } from '@mui/styles';
import { removeCustomer } from '../../services/customerServices';
import CustomerForm from './CustomerForm';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';
import { useNavigate } from 'react-router-dom';
import useConfirm from '../../hooks/useConfirm';
import AdminUserRepo from '../../core/repositories/AdminUserRepo';

const CustomerList = () => {
    const [newCustomerDialogOpen, setNewCustomerDialogOpen] =
        React.useState<boolean>(false);

    const [customerList, setCustomerList] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate();
    const { isConfirmed } = useConfirm();

    const handleDeleteCustomer = async (id?: string) => {
        try {
            setLoading(true);
            if (!id) {
                return;
            }
            await removeCustomer(id);
            await getCustomers();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const getUsersForCustomer = async (id: string) => {
        if (!id) {
            return;
        }
        const adminRepo = new AdminUserRepo();
        const data = await adminRepo.getAdminUsersForCustomerId(id);

        return data.length;
    };

    const getCustomers = async () => {
        setLoading(true);
        const ref = collection(db, 'customers');
        const snapshots = await getDocs(ref);
        const customers: any[] = [];
        snapshots.forEach((snapshot) => {
            customers.push({
                ...snapshot.data(),
                id: snapshot.id,
            });
        });

        setCustomerList(customers);
        setLoading(false);
    };

    React.useEffect(() => {
        (async () => {
            await getCustomers();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <Box sx={{ mt: 4, mb: 4 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Box />
                    <Tooltip title="Add a new customer">
                        <Button
                            startIcon={<AddBusiness />}
                            variant="outlined"
                            onClick={() => {
                                setNewCustomerDialogOpen(true);
                            }}
                        >
                            Add New Customer
                        </Button>
                    </Tooltip>
                </Stack>
            </Box>
            <ThemeProvider theme={createTheme()}>
                <MaterialTable
                    title="Customers"
                    columns={[
                        {
                            title: 'Name',
                            field: 'name',
                        },
                        {
                            title: 'Email',
                            field: 'email',
                        },
                        {
                            title: 'Chat Flow',
                            field: 'question_set_config.bot_name',
                        },
                        {
                            title: 'Active',
                            field: 'active',
                            render: (rowData) =>
                                rowData.active ? 'Active' : 'Inactive',
                        },
                        {
                            title: 'Sender Label',
                            field: 'sms_config.sender_label',
                        },
                        {
                            title: 'Created Date',
                            field: 'created_at',
                            type: 'datetime',
                            render: (rowData) => {
                                if (rowData?.created_at) {
                                    return rowData.created_at
                                        .toDate()
                                        .toLocaleString();
                                }
                                return '';
                            },
                        },
                    ]}
                    actions={[
                        (rowData) => ({
                            icon: 'edit',
                            tooltip: 'Edit Customer',
                            onClick: (event, rowData) => {
                                navigate(`/dashboard/customers/${rowData.id}`);
                            },
                        }),
                        (rowData) => ({
                            icon: 'delete',
                            tooltip: 'Delete Customer',
                            onClick: async (evt, rowData) => {
                                const users = await getUsersForCustomer(
                                    rowData?.id,
                                );
                                if (users !== 0) {
                                    await isConfirmed(
                                        `This customer have ${users} users at the moment. Please delete users before you delete customer.`,
                                    );
                                    return;
                                }
                                const confirm = await isConfirmed(
                                    'Are you sure you want to delete this user?',
                                );
                                if (!confirm) {
                                    return;
                                }
                                await handleDeleteCustomer(rowData?.id);
                            },
                        }),
                    ]}
                    data={customerList}
                    options={{
                        searchFieldVariant: 'outlined',
                        pageSize: 20,
                        pageSizeOptions: [20, 40, 60],
                        rowStyle: (rowData: any) => {
                            if (rowData.customer_status === 'historic') {
                                return { backgroundColor: '#ecc9c9' };
                            }
                            if (!rowData.active) {
                                return { backgroundColor: '#eee' };
                            }
                            return {
                                backgroundColor: '',
                            };
                        },
                    }}
                />
            </ThemeProvider>
            <Dialog
                open={newCustomerDialogOpen}
                maxWidth="md"
                fullWidth
                onClose={() => {
                    setNewCustomerDialogOpen(false);
                }}
            >
                <DialogTitle>
                    <Stack direction="row">
                        <Box sx={{ flex: 1 }}>Add Customer</Box>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    setNewCustomerDialogOpen(false);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Box>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <CustomerForm
                        onCompleted={() => {
                            setNewCustomerDialogOpen(false);
                            getCustomers();
                        }}
                    />
                </DialogContent>
            </Dialog>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default CustomerList;
