import {
    User,
    signInWithEmailAndPassword,
    OAuthProvider,
    onAuthStateChanged,
    signInWithPopup,
    signOut,
    getIdTokenResult,
    IdTokenResult,
    // setPersistence,
    //  inMemoryPersistence,
} from 'firebase/auth';
import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    where,
} from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React, { createContext } from 'react';
import { auth, db } from '../config/FirebaseConfig';
import { AuditLogService } from '../services/AuditLogService';
import { AdminUser, NamespaceType } from '../types';
// import { currentEnvironmentFromUrl } from '../utils/envUtil';
import PresenceMonitoring from './PresenceMonitoring';
import { LoadingPage } from '../pages/LoadingPage';

export interface AppAuth {
    user: User | null;
    handleLogout?: () => Promise<void>;
    handleLogin?: (email: string, password: string) => Promise<void>;
    handleAzureLogin?: () => Promise<void>;
    is_admin?: boolean;
    is_super?: boolean;
    is_internal?: boolean;
    is_system_admin?: boolean;
    is_system_user?: boolean;
    role: string;
    customer_id: string;
    customer_name: string;
    namespace: NamespaceType;
}

const provider = new OAuthProvider('microsoft.com');

const presence = new PresenceMonitoring();

// http://maximusunitedkingdom.onmicrosoft.com/
// '5074b8cc-1608-4b41-aafd-2662dd5f9bfb'
// http://maximusunitedkingdomdev.onmicrosoft.com/
// maximums dev = 1a25c064-c00a-402f-8f6c-ce0e12a6293d

// TODO: Request tenant id from DWP
provider.setCustomParameters({
    // Force re-consent.
    // prompt: 'consent',
    // Target specific email with login hint.
    login_hint: 'user@firstadd.onmicrosoft.com',
    // Optional "tenant" parameter in case you are using an Azure AD tenant.
    // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
    // or "common" for tenant-independent tokens.
    // The default value is "common".
    // tenant:
    //     currentEnvironmentFromUrl(window.location.href) === 'dev'
    //         ? '1b136b2c-eb55-4ebb-9a91-18090251efcc'
    //         : 'maximusunitedkingdom.onmicrosoft.com',
    tenant: 'maximusunitedkingdom.onmicrosoft.com',
});

export const AuthContext = createContext<AppAuth>({
    user: null,
    role: 'guest',
    customer_id: 'EQL',
    customer_name: 'EQL',
    namespace: 'PIP',
});

export const AuthenticationContext: React.FC = (props) => {
    const { children } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = React.useState(true);
    const [authUser, setAuthUser] = React.useState<User | null>(null);
    const [privileges, setPrivileges] = React.useState<{
        is_admin: boolean;
        is_super: boolean;
        is_internal: boolean;
        is_system_admin: boolean;
        is_system_user: boolean;
        role: string;
        customer_id: string;
        customer_name: string;
        namespace: 'PIP' | 'CHDA';
    }>({
        is_admin: false,
        is_super: false,
        is_internal: false,
        is_system_admin: false,
        is_system_user: false,
        role: 'guest',
        customer_id: '',
        customer_name: '',
        namespace: 'PIP',
    });

    const retrieveRole = (args: typeof privileges) => {
        const { is_admin, is_internal, is_super } = args;
        if (is_admin) {
            return 'admin';
        }
        if (is_super) {
            return 'super';
        }
        if (is_internal) {
            return 'internal';
        }
        return 'undefined';
    };

    const signInWithAzure = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            if (!result) {
                return;
            }
            // User is signed in.
            // IdP data available in result.additionalUserInfo.profile.

            // Get the OAuth access token and ID Token
            // const credential = OAuthProvider.credentialFromResult(result);
            // const accessToken = credential?.accessToken;
            // const idToken = credential?.idToken;
        } catch (e) {
            const error = e as Error;
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const getLoggedInUserInfo = async (cred: User) => {
        const ref = doc(db, 'admin_users', cred.uid);
        const document = await getDoc(ref);
        if (!document.exists()) {
            return;
        }
        const data = document.data() as AdminUser;
        return data;
    };

    const handleLogout = async () => {
        await AuditLogService.addLog({
            action_type: 'UPDATE',
            user: authUser?.email || '',
            description: 'Logged out of platform',
            role_type: retrieveRole(privileges),
            customer_id: privileges.customer_id ?? '',
        });
        await signOut(auth);
        window.location.reload();
    };

    const handleAzureToken = async (azureUser: User) => {
        try {
            const email = azureUser.email
                ? azureUser.email.split('#')[0].replace(/_/gi, '@')
                : undefined;
            if (!email) {
                enqueueSnackbar('Permission denied!', {
                    variant: 'error',
                });
                handleLogout();
                return;
            }
            const ref = collection(db, 'admin_users');
            const q = query(
                ref,
                where('email', '==', email),
                where('access_type', '==', 'AD'),
            );
            const results = await getDocs(q);
            if (!results.docs.length) {
                enqueueSnackbar('Permission denied!', {
                    variant: 'error',
                });
                handleLogout();
                return;
            }
            const record = results.docs[0].data() as AdminUser;

            setPrivileges({
                is_admin: record.role_type === 'Admin' ? true : false,
                is_super: record.role_type === 'Super' ? true : false,
                is_internal: false,
                is_system_admin:
                    record.role_type === 'System Admin' ? true : false,
                is_system_user: record.role_type === 'System' ? true : false,
                role: record.role_type.toLowerCase() ?? 'guest',
                customer_id: record.customer_id,
                customer_name: record.customer_name,
                namespace: record.namespace as NamespaceType,
            });
            await AuditLogService.addLog({
                action_type: 'READ',
                user: record.email,
                description: 'Logged into platform with Azure Active Directory',
                role_type: record.role_type.toLowerCase(),
                customer_id: record.customer_id ?? '',
            });
        } catch (e) {
            const error = e as Error;
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const handleLogin = async (email: string, password: string) => {
        const res = await signInWithEmailAndPassword(auth, email, password);
        const docRef = doc(db, 'admin_users', res.user.uid);
        const document = await getDoc(docRef);
        if (!document.exists()) {
            await AuditLogService.addLog({
                action_type: 'READ',
                user: res.user.email || res.user.uid,
                description:
                    'Logged into platform. System could not find metadata on the user',
                role_type: 'undefined',
            });
            return;
        }
        const data = document.data() as AdminUser;
        await AuditLogService.addLog({
            action_type: 'READ',
            user: data.email ?? '',
            description: 'Logged in to platform',
            role_type: data.role_type.toLowerCase(),
            customer_id: data.customer_id ?? '',
        });
    };

    const roleName = (role: IdTokenResult) => {
        if (role.claims.admin) {
            return 'admin';
        }
        if (role.claims.super) {
            return 'super';
        }
        if (role.claims.system_admin) {
            return 'system_admin';
        }
        if (role.claims.system_user) {
            return 'system_user';
        }
        return 'guest';
    };

    React.useEffect(() => {
        let isActive = true;
        const unsubAuth = onAuthStateChanged(auth, async (user) => {
            if (!isActive) {
                return;
            }
            setAuthUser(user);
            // Monitor presence
            presence.monitor();

            if (user) {
                if (user.providerData[0].providerId === 'microsoft.com') {
                    handleAzureToken(user);
                    return;
                }
                const token = await getIdTokenResult(user);
                if (!token) {
                    return;
                }
                const info = await getLoggedInUserInfo(user);
                setPrivileges({
                    is_admin: token.claims.admin ? true : false,
                    is_super: token.claims.super ? true : false,
                    is_internal: token.claims.is_internal ? true : false,
                    is_system_admin: token.claims.system_admin ? true : false,
                    is_system_user: token.claims.system ? true : false,
                    role: roleName(token),
                    customer_id: info ? info.customer_id : '',
                    customer_name: info ? info.customer_name : '',
                    namespace: info?.namespace as NamespaceType,
                });
            }
            setLoading(false);
        });
        return () => {
            unsubAuth();
            isActive = false;
        };
        // eslint-disable-next-line
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user: authUser,
                handleAzureLogin: signInWithAzure,
                handleLogout,
                handleLogin,
                ...privileges,
            }}
        >
            {loading ? <LoadingPage /> : children}
        </AuthContext.Provider>
    );
};
