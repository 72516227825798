import { Container, Box, CircularProgress } from '@mui/material';

export const LoadingPage = () => {
    return (
        <Container maxWidth="md">
            <Box pt={20} sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={60} />
            </Box>
        </Container>
    );
};
