import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { functions } from '../config/FirebaseConfig';
import {
    AdminUser,
    PIPMessage,
    Transcript,
    TranscriptResponse,
} from '../types';

class CallableServices {
    static async syncSpreadsheet(
        alsoUpdateMedicalList?: boolean,
    ): Promise<HttpsCallableResult<{ status: number; message?: string }>> {
        return httpsCallable<any, { status: number; message?: string }>(
            functions,
            'dataSync-syncNewSheet',
        )({ shouldUpdate: alsoUpdateMedicalList });
    }

    static async addAdminUser(
        newUser: AdminUser,
    ): Promise<
        HttpsCallableResult<{ status: number; message?: string; link?: string }>
    > {
        return httpsCallable<
            AdminUser,
            { status: number; message?: string; link?: string }
        >(
            functions,
            'auth-addAdminUser',
        )(newUser);
    }

    static async deleteAdminUser(
        userId: string,
    ): Promise<HttpsCallableResult<{ status: number; message?: string }>> {
        return httpsCallable<
            { user_id: string },
            { status: number; message?: string }
        >(
            functions,
            'auth-deleteAdminUser',
        )({ user_id: userId });
    }

    static async getAccessToken(userId: string): Promise<
        HttpsCallableResult<{
            status: number;
            message?: string;
            token?: string;
        }>
    > {
        return httpsCallable<
            { user_id: string },
            { status: number; message?: string }
        >(
            functions,
            'auth-authenticate',
        )({ user_id: userId });
    }

    static async retrieveAssessmentReport(
        id: string,
    ): Promise<HttpsCallableResult<TranscriptResponse>> {
        return httpsCallable<{ assessment_id: string }, Transcript>(
            functions,
            'chat-handleRetrieval',
        )({ assessment_id: id });
    }

    static async sendSimpleMessage(payload: {
        to: string;
        message: string;
        from?: string;
    }): Promise<HttpsCallableResult> {
        return httpsCallable(functions, 'sms-sendSMS')({ ...payload });
    }

    /**
     * Queue up a PIP invite message
     * @param {object} paylod
     * @returns
     */
    static async schedulePIPInvite(
        paylod: PIPMessage,
    ): Promise<HttpsCallableResult> {
        return httpsCallable(functions, 'sms-queueup')(paylod);
    }

    static async cancelPIPInvite(
        inviteId: string,
    ): Promise<HttpsCallableResult> {
        return httpsCallable(functions, 'sms-rescind')({ id: inviteId });
    }
}

export default CallableServices;
