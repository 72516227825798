import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/FirebaseConfig';
export interface Log {
    action_type: 'CREATE' | 'DELETE' | 'UPDATE' | 'READ' | 'LOG_IN';
    user: string;
    description: string;
    role_type: string;
    additional_info?: string;
    customer_id?: string;
}
export class AuditLogService {
    static async addLog(log: Log): Promise<void> {
        const ref = collection(db, 'audit_logs');
        await addDoc(ref, { ...log, created_at: serverTimestamp() });
    }
}
