import { StructuredTranscriptContent } from '../../../../types';
import { Content } from 'pdfmake/interfaces';
import { SectionTitle } from '../../../components';
import TranscriptTable from './TranscriptTable';
import { pdfStylingVars } from '../../../pdfStyle';

type Data = { content: StructuredTranscriptContent[] | undefined };

export default function FullTranscript({ content }: Data): Content {
    // Style
    const { spacingMedium } = pdfStylingVars.spacing;

    return {
        stack: [
            SectionTitle({ header: 'Full Transcript' }),
            content && content.length !== 0
                ? content.map(TranscriptTable)
                : { text: 'NIL', margin: [0, spacingMedium, 0, 0]},
        ],
    };
}
