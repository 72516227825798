import {
    collection,
    CollectionReference,
    getDocs,
    query,
    where,
} from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';
import { AdminUser } from '../../types';

class AdminUserRepo {
    private dbReference: CollectionReference;

    constructor() {
        this.dbReference = collection(db, 'admin_users');
    }

    async getAllAdminUsers(): Promise<AdminUser[] | undefined> {
        const _query = query(
            this.dbReference,
            where('role_type', 'in', ['Admin', 'Super']),
        );
        const results = await getDocs(_query);
        if (!results.docs.length) {
            return undefined;
        }
        const temp: AdminUser[] = [];
        results.forEach((doc) => {
            const data = { ...doc.data(), id: doc.id } as AdminUser;
            temp.push(data);
        });
        return temp;
    }

    async getAdminUsersForCustomerId(customerId: string): Promise<AdminUser[]> {
        const _query = query(
            this.dbReference,
            where('customer_id', '==', customerId),
        );
        const results = await getDocs(_query);
        if (!results.docs.length) {
            return [];
        }
        const temp: AdminUser[] = [];
        results.forEach((doc) => {
            const data = { ...doc.data(), id: doc.id } as AdminUser;
            temp.push(data);
        });
        return temp;
    }

    async getUserWithEmail(email: string): Promise<AdminUser | undefined> {
        const docquery = query(this.dbReference, where('email', '==', email));
        const results = await getDocs(docquery);
        if (!results.docs.length) {
            return undefined;
        }
        const data = {
            ...results.docs[0].data(),
            id: results.docs[0].id,
        } as AdminUser;
        return data;
    }
}

export default AdminUserRepo;
