import React, { useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Chip, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import CallableServices from '../../services/CallableServices';

const MAX_CHAR_LEN = 500;

const DispatchSmsContainer = () => {
    const [isSending, setIsSending] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [singleContact, setSingleContact] = React.useState<string | null>(
        null,
    );
    const [characterCount, setCharacterCount] = React.useState(MAX_CHAR_LEN);
    const { enqueueSnackbar } = useSnackbar();

    const handleMessageInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const count = MAX_CHAR_LEN - value.length;
        if (count < 0) {
            setCharacterCount(0);
            return;
        }
        setMessage(value);
        setCharacterCount(count);
    };

    const handleOnMessageInputBlur = (
        event: React.FocusEvent<
            HTMLInputElement | HTMLTextAreaElement,
            Element
        >,
    ) => {
        const { value } = event.target;
        const count = MAX_CHAR_LEN - value.length;
        if (count < 0) {
            enqueueSnackbar('Message too long', { variant: 'error' });
            setCharacterCount(0);
            return;
        }
        setMessage(value);
        setCharacterCount(count);
    };

    const sendSms = useCallback(async () => {
        try {
            if (!singleContact) {
                return;
            }
            setIsSending(true);
            const recipients = singleContact?.split(',').join(';');
            const payload = {
                message,
                to: recipients,
                from: 'Phio',
            };
            await CallableServices.sendSimpleMessage(payload);
            setIsSending(false);
            enqueueSnackbar('Message sent successfully', {
                variant: 'success',
            });
        } catch (e) {
            setIsSending(false);
            const err = e as Error;
            enqueueSnackbar(err.message, { variant: 'error' });
        }
        // eslint-disable-next-line
    }, [message, singleContact]);

    return (
        <Stack direction="column">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    '& > :not(style)': { m: 1 },
                }}
            >
                <Typography color="InfoText">
                    Phone number validation is not applied. Please ensure that
                    the numbers are valid
                </Typography>
                <LoadingButton
                    variant="outlined"
                    size="large"
                    onClick={() => sendSms()}
                    loading={isSending}
                    disabled={!singleContact || !message}
                >
                    SEND SMS
                </LoadingButton>
            </Box>
            <Box>
                <Stack direction="row" spacing={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    >
                        <TextField
                            variant="outlined"
                            aria-label="phone number"
                            label="Enter Phone Number"
                            fullWidth
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                const { value } = event.target;
                                if (value.trim() === '') {
                                    setSingleContact(null);
                                    return;
                                }
                                setSingleContact(event.target.value);
                            }}
                            value={singleContact || ''}
                            placeholder="Enter phone number or numbers separated by comma"
                            helperText="Click on the add button to add the entered number"
                        />
                        <Box p={2} sx={{ width: 320 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 1,
                                }}
                            >
                                {singleContact
                                    ?.trim()
                                    .split(',')
                                    .map((contact, index) => (
                                        <Chip
                                            key={`single_contact_${index}`}
                                            label={contact}
                                        />
                                    ))}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    >
                        <TextField
                            fullWidth
                            label="Message Text"
                            multiline
                            rows={6}
                            variant="outlined"
                            onChange={handleMessageInput}
                            // value={message}
                            placeholder="Enter message text"
                            onBlur={handleOnMessageInputBlur}
                            focused
                        />
                        <Box p={1}>
                            <span>Character count {characterCount}</span>
                        </Box>
                    </Box>
                </Stack>
            </Box>
        </Stack>
    );
};

export default DispatchSmsContainer;
