import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { functions } from '../config/FirebaseConfig';

type Status = { status: number; message: string };

export const reviewAssessment = async (
    assessmentId: string,
    reviewed: boolean,
    namespace: 'PIP' | 'CHDA',
): Promise<HttpsCallableResult<Status>> => {
    return httpsCallable<
        { assessment_id: string; reviewed: boolean; namespace: 'PIP' | 'CHDA' },
        Status
    >(
        functions,
        'assessments-review',
    )({ assessment_id: assessmentId, reviewed, namespace });
};
