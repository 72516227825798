import { initializeApp } from 'firebase/app';
import {
    // connectFirestoreEmulator,
    getFirestore,
} from 'firebase/firestore';
import {
    // connectFunctionsEmulator,
    getFunctions,
} from 'firebase/functions';
import {
    //  connectAuthEmulator,
    getAuth,
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
};

// const prodConfigForTesting = {
//     apiKey: 'AIzaSyAtno-4OPs9_JzQ8wzLRpSDzQKRBeS2lWI',
//     authDomain: 'infoshare-1684f.firebaseapp.com',
//     databaseURL:
//         'https://infoshare-1684f-default-rtdb.europe-west1.firebasedatabase.app',
//     projectId: 'infoshare-1684f',
//     storageBucket: 'infoshare-1684f.appspot.com',
//     messagingSenderId: '52819021130',
//     appId: '1:52819021130:web:92518ebfe430102fa4c5b3',
//     measurementId: 'G-1P1X4182E2',
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore();
const storage = getStorage();
const auth = getAuth(app);
const functions = getFunctions(app, 'europe-west2');

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
        '6LeheuEeAAAAAGKqLRZdF3kipKP3bq9G6E3N1g4d',
    ),
    isTokenAutoRefreshEnabled: true,
});

// @remark: This is only for local development
// WARNING: Only use this in development mode if you can connect to the emulator else comment this out
// if (window.location.href.match(/localhost/)) {
//     connectFunctionsEmulator(functions, 'localhost', 5001);
//     connectFirestoreEmulator(db, 'localhost', 8080);
//     connectAuthEmulator(auth, 'http://127.0.0.1:9099', {
//         disableWarnings: true,
//     });
// }

export { db, storage, functions, app, auth, appCheck };
