import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Stack,
    TextField,
    styled,
} from '@mui/material';
import { useFormik } from 'formik';
import { boolean, object, string } from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../hooks/useAuth';
import CallableServices from '../../services/CallableServices';

export const NumberField = styled(TextField)(() => ({
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
    '& input[type=number]': {
        MozAppearance: 'textfield',
    },
}));

interface Props {
    open: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validUKphone = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;

const registrationSchema = object({
    phone_number: string()
        .matches(
            validUKphone,
            'Please enter a valid UK mobile telephone number.',
        )
        .required('Phone number is required'),
    supplementary_info: object({
        activity_9: boolean(),
        aids_and_assistance: boolean(),
        activity_11: boolean(),
        activity_12: boolean(),
        prompts_and_supervision: boolean(),
    }).test(
        'OK',
        'Please select at least one area of supplementary information you require.',
        (item) =>
            (item.activity_9 ||
                item.aids_and_assistance ||
                item.activity_11 ||
                item.activity_12 ||
                item.prompts_and_supervision) as boolean,
    ),
});

export default function CreateInvite(props: Props) {
    const { open, onClose } = props;

    const [loading, setLoading] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const authObj = useAuth();

    const submitInvitation = async (data: any) => {
        await CallableServices.schedulePIPInvite(data);
        formik.resetForm();
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            phone_number: '',
            supplementary_info: {
                activity_9: false,
                aids_and_assistance: false,
                activity_11: false,
                activity_12: false,
                prompts_and_supervision: false,
            },
        },
        validationSchema: registrationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: async (values) => {
            try {
                setLoading(true);

                await submitInvitation({
                    ...values,
                    customer_id: authObj.customer_id,
                    customer_name: authObj.customer_name,
                    created_by: authObj.user?.email,
                    namespace: authObj.namespace,
                });
                enqueueSnackbar('Invitation created successfully', {
                    variant: 'success',
                });
                onClose();
            } catch (e) {
                console.error(e);
                const err = e as Error;
                enqueueSnackbar(err.message, { variant: 'error' });
            } finally {
                setLoading(false);
            }
        },
    });

    React.useEffect(() => {
        formik.setFieldTouched('supplementary_info', true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.supplementary_info]);

    React.useEffect(() => {
        formik.setFieldTouched('supplementary_info', false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="refer-customer-dialog"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Refer a customer</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Stack spacing={2} sx={{ mb: 4 }}>
                        <NumberField
                            type="numeric"
                            variant="outlined"
                            label="Mobile Phone"
                            fullWidth
                            id="phone_number"
                            error={
                                formik.touched.phone_number &&
                                Boolean(formik.errors.phone_number)
                            }
                            helperText={
                                formik.touched.phone_number &&
                                formik.errors.phone_number
                            }
                            {...formik.getFieldProps('phone_number')}
                            required
                            inputProps={{
                                onWheel: (e: any) => e.target.blur(),
                                onKeyDown: (e) =>
                                    ['e', 'E', '-'].includes(e.key) &&
                                    e.preventDefault(),
                            }}
                            value={formik.values.phone_number.toString()}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ mt: 4, mb: 4 }}>
                        <FormControl
                            required
                            error={
                                formik.touched.supplementary_info &&
                                Boolean(formik.errors.supplementary_info)
                            }
                            component="fieldset"
                        >
                            <FormLabel component="legend">
                                Supplementary Information Required
                            </FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                formik.values.supplementary_info
                                                    .activity_9
                                            }
                                            onChange={formik.handleChange}
                                            name="supplementary_info.activity_9"
                                        />
                                    }
                                    label="Activity 9"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                formik.values.supplementary_info
                                                    .aids_and_assistance
                                            }
                                            onChange={formik.handleChange}
                                            name="supplementary_info.aids_and_assistance"
                                        />
                                    }
                                    label="Aids and Assistance"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                formik.values.supplementary_info
                                                    .prompts_and_supervision
                                            }
                                            onChange={formik.handleChange}
                                            name="supplementary_info.prompts_and_supervision"
                                        />
                                    }
                                    label="Prompts and Supervision"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                formik.values.supplementary_info
                                                    .activity_11
                                            }
                                            onChange={formik.handleChange}
                                            name="supplementary_info.activity_11"
                                        />
                                    }
                                    label="Activity 11"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                formik.values.supplementary_info
                                                    .activity_12
                                            }
                                            onChange={formik.handleChange}
                                            name="supplementary_info.activity_12"
                                        />
                                    }
                                    label="Activity 12"
                                />
                            </FormGroup>
                            {formik.touched.supplementary_info &&
                                Boolean(formik.errors.supplementary_info) && (
                                    <FormHelperText>
                                        {formik.errors.supplementary_info}
                                    </FormHelperText>
                                )}
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ mr: 2 }}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                onClose();
                            }}
                            sx={{ mr: 2 }}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            variant="contained"
                            disabled={!formik.isValid}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
}
