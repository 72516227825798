import { Content, TableCell } from 'pdfmake/interfaces';
import { pdfStylingVars } from '../pdfStyle';
import { StructuredTranscriptContent } from '../../types';

function ConditionTable(data: StructuredTranscriptContent): Content {
    const { header, subheader, body, footer } = data;
    // Style
    const { colors, spacing } = pdfStylingVars;
    const { spacingMedium } = spacing;

    const tableBody: TableCell[][] = [
        [
            body.join('\n'),
        ],
        [
            {
                text: footer,
                style: ['subtitle1'],
                bold: true,
                color: colors.secondary,
            },
        ],
    ];

    if (header) {
        tableBody.unshift([
            {
                stack: [
                    {
                        text: header,
                        style: 'heading6',
                        lineHeight: 1.2,
                    },
                    {
                        text: subheader,
                        style: 'subtitle1',
                        lineHeight: 1.2,
                        color: colors.primaryDarker,
                    },
                ],
            },
        ]);
    }

    return {
        margin: [0, spacingMedium, 0, 0],
        table: {
            widths: '*',
            body: tableBody,
        },
        layout: 'default',
    };
}

export default ConditionTable;
