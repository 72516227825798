import { uploadBytes, getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../config/FirebaseConfig';

interface Image {
    file: File;
    fileName: string;
}

async function uploadImage(image: Image, id: string) {
    const storageRef = ref(storage, `customer_logos/${image.fileName}_${id}`);

    const response = await uploadBytes(storageRef, image.file);
    const url = await getDownloadURL(response.ref);
    return { [image.fileName]: url };
}

export default async function uploadImages(images: Image[], id: string) {
    const imagePromises = Array.from(images, (image) => uploadImage(image, id));

    const imageRes = await Promise.all(imagePromises);

    const listOfRes = imageRes.reduce((prev, curr) => {
        Object.assign(prev, curr);
        return prev;
    }, {});

    return listOfRes; // list of url like {"logo_light": "https://..", "logo_dark": "https://..", ...},
}
