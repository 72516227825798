import { StructuredTranscriptContent } from '../../../../types';
import { Content, TableCell } from 'pdfmake/interfaces';
import { pdfStylingVars } from '../../../pdfStyle';

function qAndAs(value: string): TableCell[] {
    const regex = /{(.*)}/;
    const question = value.replace(regex, '');
    const answer = value.match(regex)?.pop();

    return [
        question,
        answer || 'NIL',
    ];
}

function TranscriptTable(data: StructuredTranscriptContent): Content {
    const { header, body } = data;

    return {
        margin: [0, pdfStylingVars.spacing.spacingMedium, 0, 0],
        table: {
            widths: ['*', 120],
            body: [
                [
                    {
                        text: header,
                        bold: true,
                        colSpan: 2,
                        fillColor: pdfStylingVars.colors.primaryLighter,
                    },
                    {},
                ],
                ...body.map(qAndAs),
            ],
        },
        layout: 'default',
    };
}

export default TranscriptTable;
