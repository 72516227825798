import { Content } from 'pdfmake/interfaces';
import { pdfStylingVars } from '../../../pdfStyle';
import { SectionTitle } from '../../../components';

interface Data {
    content: Array<{
        header: string;
        body: string[];
    }>;
}

export default function Miscellaneous(data: Data): Content {
    const { content } = data;
    // Style
    const { spacingMedium } = pdfStylingVars.spacing;

    return {
        stack: [
            SectionTitle({ header: '08: Miscellaneous' }),
            content.map((value) => ({
                margin: [0, spacingMedium, 0, 0],
                table: {
                    widths: ['*'],
                    body: [
                        [
                            {
                                text: value.header,
                                style: 'heading6',
                                lineHeight: 1.2,
                            },
                        ],
                        [
                            value.body.length !== 0
                                ? value.body.join('\n')
                                : 'NIL',
                        ],
                    ],
                },
                layout: 'default',
            })),
        ],
    };
}
