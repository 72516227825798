import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { FormHelperText, Grid, Link } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

const SignInPasswordAndEmail = () => {
    const [state, setState] = React.useState<{
        email: string;
        password: string;
        policyAccepted: boolean;
        [propName: string]: any;
    }>({
        email: '',
        password: '',
        policyAccepted: false,
    });

    const [loading, setLoading] = React.useState(false);

    const authObj = useAuth();

    const { enqueueSnackbar } = useSnackbar();

    const handleAzureLogin = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (!state.policyAccepted) {
            enqueueSnackbar(
                'Please accept our policy before by checking the box above',
                { variant: 'info' },
            );
            return;
        }
        (async () => {
            if (!authObj || !authObj.handleAzureLogin) {
                return;
            }
            await authObj.handleAzureLogin();
        })();
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setState({
            ...state,
            [`${name}`]: value,
        });
    };

    const disableButton = () => {
        const { email, password, policyAccepted } = state;
        return email.trim() === '' || password.trim() === '' || !policyAccepted;
    };

    const handleSubmit = async () => {
        try {
            if (!authObj || !authObj.handleLogin) {
                return;
            }
            setLoading(true);
            await authObj.handleLogin(state.email, state.password);
        } catch (e) {
            console.log(e);
            enqueueSnackbar('Login attempt failed', {
                variant: 'error',
            });
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleChangeInput}
                        error={state.email.trim() === ''}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        onChange={handleChangeInput}
                        autoComplete="current-password"
                        error={state.password.trim() === ''}
                    />
                    <FormHelperText component={'div'}>
                        We, EQL Limited (co no 11806513) provide the Health
                        Information Share (HIS) platform. HIS is for the use of
                        authorised users only. By clicking on the OK button
                        below and logging into HIS, you agree to the following:
                        <ul>
                            <li>
                                You will abide by our acceptable use policy
                                specified in paragraph 8 of our terms of use and
                                only use HIS for its intended purpose, being the
                                management of the process for universal credit
                                applicants who complete their questionnaire
                                using HIS.
                            </li>
                            <li>
                                You are solely responsible for ensuring the
                                device and connectivity used to access HIS are
                                private and secure and for keeping any access
                                credentials provided confidential.
                            </li>
                            <li>
                                All individuals using HIS with or without
                                authority, or in excess of their authority, are
                                subject to having all of their activities in HIS
                                and downloading or extracting from HIS monitored
                                and recorded by EQL. Anyone using HIS expressly
                                consents to such monitoring and is advised that
                                if such monitoring reveals possible evidence of
                                criminal activity, we may provide the evidence
                                of such monitoring to law enforcement officials.
                            </li>
                            <li>
                                To the maximum extent permitted by law, EQL
                                excludes liability for all claims, actions and
                                losses arising out of or in connection with your
                                use of HIS.
                            </li>
                        </ul>
                        EQL reserves the right to amend or revoke your right to
                        use HIS at any time.
                    </FormHelperText>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={state.policyAccepted}
                                color="primary"
                                inputProps={{
                                    'aria-label': 'Usage Policy',
                                }}
                                onChange={(evt) =>
                                    setState({
                                        ...state,
                                        policyAccepted: evt.target.checked,
                                    })
                                }
                            />
                        }
                        label="By logging in, I accept the Acceptable Usage Policy above."
                        id="policy"
                    />
                    <LoadingButton
                        onClick={handleSubmit}
                        fullWidth
                        variant="contained"
                        disabled={disableButton()}
                        sx={{ mt: 3, mb: 2 }}
                        loading={loading}
                    >
                        Sign In
                    </LoadingButton>
                    <Grid container mb="20px">
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            textAlign={{ xs: 'center', sm: 'left' }}
                        >
                            <Link
                                href="/auth/active-directory"
                                variant="body2"
                                onClick={handleAzureLogin}
                            >
                                Login with Azure Active Directory
                            </Link>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            textAlign={{ xs: 'center', sm: 'right' }}
                        >
                            <Link href="/forgot-password" variant="body2">
                                Forgotten Password?
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default SignInPasswordAndEmail;
