import { LoadingButton } from '@mui/lab';
import {
    Container,
    Stack,
    Typography,
    Box,
    Avatar,
    CssBaseline,
    TextField,
    Link,
    Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { object, string } from 'yup';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useSnackbar } from 'notistack';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../config/FirebaseConfig';

const Schema = object({
    email: string()
        .email('Please enter a valid email address')
        .required('Email address is required'),
});

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Schema,
        initialErrors: {
            email: 'Email address is required',
        },
        onSubmit: async (values) => {
            setLoading(true);

            try {
                await sendPasswordResetEmail(auth, values.email);
            } finally {
                setLoading(false);
                enqueueSnackbar(
                    `Please check your email for instructions on how to reset your password`,
                    {
                        variant: 'success',
                    },
                );
                formik.resetForm();
            }
        },
    });
    return (
        <Container maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    sx={{
                        mt: 1,
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Forgot Password
                    </Typography>
                    <Stack
                        spacing={2}
                        sx={{
                            mt: 4,
                            mb: 4,
                            width: { xs: '90%', sm: '70%', md: '50%' },
                        }}
                        direction="column"
                    >
                        <TextField
                            label="Email Address"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                            required
                        />
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={loading}
                            disabled={loading || !formik.isValid}
                        >
                            Submit
                        </LoadingButton>

                        <Grid container mb="20px">
                            <Grid item xs textAlign="center">
                                <Link href="/" variant="body2">
                                    Return to Login
                                </Link>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
};

export default ForgotPassword;
