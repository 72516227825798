import { Content } from 'pdfmake/interfaces';
import { pdfStylingVars } from '../pdfStyle';

export default function Header(text: string): Content {
    // Styling
    const { pageMargins, spacingLarge } = pdfStylingVars.spacing;

    return {
        margin: [pageMargins[0], spacingLarge, pageMargins[0], 0],
        alignment: 'right',
        style: 'heading6',
        color: pdfStylingVars.colors.primary,
        text,
    };
}
