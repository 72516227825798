import { Content } from 'pdfmake/interfaces';
import { ConditionTable, SectionTitle } from '../../../components';
import { StructuredTranscript } from '../../../../types';
import { pdfStylingVars } from '../../../pdfStyle';

export default function ConditionHistory(data: StructuredTranscript): Content {
    const { header, subheader, content } = data;
    // Style
    const { spacingMedium } = pdfStylingVars.spacing;

    return {
        stack: [
            SectionTitle({ header, subheader }),
            content.length !== 0
                ? content.map(ConditionTable)
                : { text: 'NIL', margin: [0, spacingMedium, 0, 0] },
        ],
    };
}
