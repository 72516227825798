import { useState } from 'react';
import {
    Stack,
    Divider,
    Box,
    Typography,
    ImageListItem,
    ImageList,
    Button,
    Paper,
    Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { v4 as uuidv4 } from 'uuid';

interface LogoSelectFormProps {
    formik: any;
    customerLogoUrl?: string;
    fieldName: string;
    previewBackground?: string;
    error?: any;
    logoSizes?: { base: string; sm: string }[];
    required?: boolean;
}

interface LogoProps {
    src?: string;
    alt?: string;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const LogoUpload = (props: LogoSelectFormProps) => {
    const {
        formik,
        customerLogoUrl,
        fieldName,
        previewBackground,
        error,
        logoSizes,
        required,
    } = props;

    const [file, setFile] = useState<boolean>(false);

    const imageUrl =
        file &&
        formik.values[fieldName] &&
        URL.createObjectURL(formik.values[fieldName]);

    const defaultLogoSizes = [
        { base: '34px', sm: '42px' },
        { base: '44px', sm: '52px' },
        { base: '64px', sm: '82px' },
    ];

    const renderLogos = ({ src, alt }: LogoProps) => {
        const sizes = logoSizes || defaultLogoSizes;
        return (
            <ImageList sx={{ width: 400 }} cols={1}>
                {sizes.map((size) => (
                    <ImageListItem
                        key={uuidv4()}
                        sx={{
                            alignItems: 'center',
                            background: previewBackground || '#dbdcf4',
                            width: 400,
                            py: '5px',
                        }}
                    >
                        <Box
                            component="img"
                            height={size}
                            src={src}
                            alt={alt || 'Customer logo'}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        );
    };

    return (
        <Box>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
            >
                <Box sx={{ minHeight: 180, minWidth: 250 }}>
                    <Typography>Current Logo</Typography>

                    {customerLogoUrl ? (
                        renderLogos({
                            src: customerLogoUrl,
                        })
                    ) : (
                        <Paper
                            variant="outlined"
                            sx={{
                                mt: 2,
                                width: 250,
                                height: 120,
                                p: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography>Logo not provided</Typography>
                        </Paper>
                    )}
                </Box>
                <Box>
                    <Stack
                        direction="row"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload file
                            <VisuallyHiddenInput
                                type="file"
                                id={fieldName}
                                name={fieldName}
                                accept="image/png,image/jpeg,image/webp"
                                value={undefined}
                                onChange={(event) => {
                                    if (event.target.files) {
                                        formik.setFieldValue(
                                            fieldName,
                                            event.target.files[0],
                                        );
                                        setFile(true);
                                    }
                                }}
                                required={required}
                            />
                        </Button>
                        <Typography
                            variant="body1"
                            display="block"
                            ml={2}
                            color={error ? 'red' : ''}
                        >
                            {formik.values[fieldName]?.name}
                        </Typography>
                    </Stack>
                    <Typography variant="caption" display="block" gutterBottom>
                        Accepted files formats: .png, .jpeg, .webp. Max 200KB
                        file size.
                    </Typography>
                    {error && <Alert severity="error">{error}</Alert>}
                    {imageUrl && (
                        <>
                            <Typography mt={2}>Logo preview</Typography>
                            {renderLogos({
                                src: imageUrl,
                                alt: 'Customer logo preview',
                            })}
                        </>
                    )}
                </Box>
            </Stack>
        </Box>
    );
};

export default LogoUpload;
