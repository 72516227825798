import { Content } from 'pdfmake/interfaces';
import { pdfStylingVars } from '../pdfStyle';

interface Data {
    vMargin?: number;
    color?: string;
    length?: number;
    thickness?: number;
}

export default function HorizontalLine(data?: Data): Content {
    return {
        canvas: [
            {
                type: 'line',
                x1: 0,
                x2: data?.length || 595 - 2 * pdfStylingVars.spacing.pageMargins[0],
                y1: data?.vMargin || 0,
                y2: data?.vMargin || 0,
                lineWidth: data?.thickness || 1,
                lineColor: data?.color || pdfStylingVars.colors.primary,
            }
        ]
    }
}
