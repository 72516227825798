import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const AuthRequired = ({ children }: { children: JSX.Element }) => {
    const authCtx = useAuth();
    const location = useLocation();
    return authCtx.user ? (
        children
    ) : (
        <Navigate to="/" state={{ from: location }} replace />
    );
};

export default AuthRequired;
