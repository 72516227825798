import { useFormik } from 'formik';
import React from 'react';
import { object, string } from 'yup';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
    InternalUser,
    addSystemUser,
    updateRole,
} from '../../services/systemUserServices';
import { currentEnvironmentFromUrl } from '../../utils/envUtil';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../config/FirebaseConfig';

const internalEmailRegex = /^[A-Za-z0-9._%+-]+@eql\.ai$/;

const setupConfig = object({
    name: string().required('Customer name is required'),
    email: string()
        .email('Invalid email')
        .matches(internalEmailRegex, 'Needs to be internal email')
        .required('Email address is required'),
});

interface Props {
    onCompleted?: () => void;
    data: InternalUser | undefined;
}

export const SysRoles = ['System', 'System Admin'];

const SystemUserForm = (props: Props) => {
    const { onCompleted, data } = props;
    const [loading, setLoading] = React.useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();
    const formik = useFormik({
        initialValues: {
            name: data?.name || '',
            email: data?.email || '',
            role_type: data?.role_type || 'System',
        },
        validationSchema: setupConfig,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        initialErrors: {
            name: 'Name is required',
            email: 'Email address is required',
            role_type: 'Role is required',
        },
        onSubmit: async (values) => {
            try {
                setLoading(true);
                if (data?.id) {
                    await updateRole(data.id, values.role_type);
                    enqueueSnackbar('System user updated successfully', {
                        variant: 'success',
                    });
                } else {
                    await addSystemUser(values as InternalUser);
                    const curEnv = currentEnvironmentFromUrl(
                        window.location.href,
                    );
                    const url =
                        curEnv === 'dev'
                            ? 'https://pat-admin.web.app/'
                            : 'https://admin.infoshare.uk/';
                    await sendPasswordResetEmail(auth, values.email, {
                        url,
                    });
                    enqueueSnackbar('System user added successfully', {
                        variant: 'success',
                    });
                }

                if (onCompleted) {
                    onCompleted();
                }
            } catch (e) {
                console.error(e);
                const err = e as Error;
                enqueueSnackbar(err.message, { variant: 'error' });
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2} sx={{ mt: 4, mb: 4 }}>
                    <TextField
                        variant="outlined"
                        label="Name"
                        fullWidth
                        id="name"
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                        required
                        {...formik.getFieldProps('name')}
                        disabled={!!data?.name}
                    />
                    <TextField
                        variant="outlined"
                        label="Email Address"
                        fullWidth
                        id="email"
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        required
                        {...formik.getFieldProps('email')}
                        disabled={!!data?.email}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Role Type
                        </InputLabel>
                        <Select
                            label="Role Type"
                            labelId="demo-simple-select-label"
                            required
                            {...formik.getFieldProps('role_type')}
                        >
                            {SysRoles.map((role) => (
                                <MenuItem key={role.toLowerCase()} value={role}>
                                    {role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Box />
                    <LoadingButton
                        loading={loading}
                        variant="outlined"
                        type="submit"
                        disabled={loading || !formik.isValid}
                    >
                        Submit
                    </LoadingButton>
                </Stack>
            </form>
        </Box>
    );
};

export default SystemUserForm;
