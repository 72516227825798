import {
    CustomTableLayout,
    StyleDictionary,
} from 'pdfmake/interfaces';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

/**
 * Common images that need to be reused across multiple PDF Documents in base64 format.
 * This can include branding images.
 */
export const pdfCommonImages = {};

/**
 * Styling values needed for a common theme.
 */
export const pdfStylingVars = {
    colors: {
        primary: '#999999',
        primaryDarker: '#666666',
        primaryLighter: '#efefef',
        secondary: '#97a0af',

        // Body text colours
        bodyText: '#000000',
        bodyTextLighter: '#999999',
        bodyTextOnPrimary: '#FFFFFF',
    },
    // General spacing and sizes
    spacing: {
        // Page margins [left, top, right, bottom]
        pageMargins: [50, 60, 50, 60] as [number, number, number, number],

        spacingSmall: 3, // e.g. between text paragraphs
        spacingMedium: 9, // e.g. between image and text
        spacingLarge: 30, // e.g. between two different sections
    },
    tables: {
        // h = horizontal (left & right)
        hPaddingCell: 5,
        hPaddingHeaderCell: 5,
        // v = vertical (top & bottom)
        vPaddingCell: 5,
        vPaddingHeaderCell: 5,
    },
};

const { colors } = pdfStylingVars;

/**
 * Common PDF styling classes for creating different PDF documents with a cohesive
 * and consistent style.
 */
export const pdfCommonStyle: StyleDictionary = {
    // Typography (using the golden ratio)
    heading1: { fontSize: 26.25, bold: true },
    heading2: { fontSize: 23.75, bold: true },
    heading3: { fontSize: 17.75, bold: true },
    heading4: { fontSize: 14.75, bold: true },
    heading5: { fontSize: 13.25, bold: true },
    heading6: { fontSize: 12, bold: true },
    subtitle1: { fontSize: 12 },
    body1: { fontSize: 11, color: colors.bodyText, lineHeight: 1.4 },
    body2: { fontSize: 10.5, color: colors.bodyText },
    caption: { fontSize: 10 },
    footnote: { fontSize: 9 },

    // Tables
    headerCell: {
        color: colors.bodyTextOnPrimary,
        fillColor: colors.primary,
    },
};

const { tables } = pdfStylingVars;

/**
 * All table layouts.
 */
export const pdfTableLayouts: { default: CustomTableLayout } = {
    default: {
        hLineColor: colors.primary,
        vLineColor: colors.primary,
        hLineWidth: () => 0.1,
        vLineWidth: () => 0.1,

        paddingTop: () => tables.vPaddingCell,
        paddingBottom: () => tables.vPaddingCell,
        paddingLeft: () => tables.hPaddingCell,
        paddingRight: () => tables.hPaddingCell,
    },
};

// Adding all table layouts to the pdfMake instance
pdfMake.tableLayouts = pdfTableLayouts;

// Initialise pdfMake fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;
