import React from 'react';
import { Box, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';
import { useSnackbar } from 'notistack';

const MAX_CHAR_LEN = 500;

const SettingsView = () => {
    const [characterCount, setCharacterCount] = React.useState(MAX_CHAR_LEN);
    const [message, setMessage] = React.useState('');
    const [ttl, setTTL] = React.useState(1);
    const [updatingContent, setUpdatingContent] = React.useState(false);
    const [updatingTTL, setUpdatingTTL] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleMessageInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const count = MAX_CHAR_LEN - value.length;
        if (count < 0) {
            enqueueSnackbar('Message too long', { variant: 'error' });
            return;
        }
        setMessage(value);
        setCharacterCount(count);
    };

    const handleOnMessageInputBlur = (
        event: React.FocusEvent<
            HTMLInputElement | HTMLTextAreaElement,
            Element
        >,
    ) => {
        const { value } = event.target;
        const count = MAX_CHAR_LEN - value.length;
        if (count < 0) {
            enqueueSnackbar('Message too long', { variant: 'error' });
            return;
        }
        setMessage(value);
        setCharacterCount(count);
    };

    const documentExists = async () => {
        const ref = doc(db, 'sms_config', 'default');
        const docSnap = await getDoc(ref);
        return docSnap.exists();
    };

    const handleUpdateContent = async () => {
        try {
            setUpdatingContent(true);
            const docExists = await documentExists();
            if (!docExists) {
                await setDoc(doc(db, 'sms_config', 'default'), {
                    content: message,
                });
            } else {
                await updateDoc(doc(db, 'sms_config', 'default'), {
                    content: message,
                });
            }
            setUpdatingContent(false);
            enqueueSnackbar('Message updated successfully', {
                variant: 'success',
            });
        } catch (e) {
            setUpdatingContent(false);
            console.error(e);
            enqueueSnackbar('Error updating message', { variant: 'error' });
        }
    };

    const handleUpdateTTL = async () => {
        try {
            setUpdatingTTL(true);
            const docExists = await documentExists();
            if (!docExists) {
                await setDoc(doc(db, 'sms_config', 'default'), { ttl: 1 });
            } else {
                await updateDoc(doc(db, 'sms_config', 'default'), { ttl });
            }
            setUpdatingTTL(false);
            enqueueSnackbar('TTL updated successfully', { variant: 'success' });
        } catch (e) {
            setUpdatingTTL(false);
            console.error(e);
            enqueueSnackbar('Error updating TTL', { variant: 'error' });
        }
    };

    React.useEffect(() => {
        (async () => {
            const ref = doc(db, 'sms_config', 'default');
            const docSnap = await getDoc(ref);
            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data) {
                    setMessage(data.content);
                    setTTL(data.ttl);
                }
            }
        })();
    }, []);

    return (
        <Stack direction="row">
            <Box
                sx={{
                    display: 'flex',
                    flex: 2,
                    flexDirection: 'column',
                }}
                mt={2}
            >
                <TextField
                    fullWidth
                    label="Default message"
                    multiline
                    rows={6}
                    variant="outlined"
                    onChange={handleMessageInput}
                    // value={message || ''}
                    placeholder="Use this box to set the default message for your SMS"
                    onBlur={handleOnMessageInputBlur}
                    defaultValue={message}
                    focused
                />
                <Box p={1}>
                    <span>Character count {characterCount}</span>
                </Box>
                <Box p={1}>
                    <LoadingButton
                        loading={updatingContent}
                        variant="contained"
                        onClick={handleUpdateContent}
                    >
                        Update content
                    </LoadingButton>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                }}
                mt={2}
                ml={2}
            >
                <TextField
                    label="Dispatch SMS within (days)"
                    type="number"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0, max: 20 } }}
                    value={ttl}
                    onChange={(event) => {
                        const { value } = event.target;
                        if (value.trim() === '') {
                            setTTL(0);
                            return;
                        }
                        setTTL(parseInt(value));
                    }}
                />
                <Box p={1}>
                    <LoadingButton
                        loading={updatingTTL}
                        onClick={handleUpdateTTL}
                        variant="outlined"
                    >
                        Update
                    </LoadingButton>
                </Box>
            </Box>
        </Stack>
    );
};

export default SettingsView;
