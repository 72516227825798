import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { functions } from '../config/FirebaseConfig';

export type InternalUser = {
    email: string;
    name: string;
    role_type: 'System' | 'System Admin';
    id?: string;
};

/**
 * Creates a new system user
 * @param {object} user - The user to add
 * @returns {Promise<object>}
 */
export const addSystemUser = async (
    user: InternalUser,
): Promise<HttpsCallableResult<unknown>> => {
    return httpsCallable<InternalUser, unknown>(
        functions,
        'auth-addInternalSystemUser',
    )(user);
};

/**
 * Removes a system user
 * @param {string} id - The id of the user to remove
 * @returns
 */
export const removeSystemUser = async (
    id: string,
): Promise<HttpsCallableResult<unknown>> => {
    return httpsCallable<{ id: string }, unknown>(
        functions,
        'auth-deleteInternalSystemUser',
    )({ id });
};

/**
 * Updates the role of a system user
 * @param id - The id of the user to update
 * @param newRole - The new role to assign to the user
 * @returns {Promise<object>}
 */
export const updateRole = async (
    id: string,
    newRole: 'System' | 'System Admin',
): Promise<HttpsCallableResult> => {
    return httpsCallable<{ id: string; new_role: 'System' | 'System Admin' }>(
        functions,
        'auth-updateSystemRole',
    )({ id, new_role: newRole });
};
