import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

interface Props {
    label: string;
    callback?: (param: Date) => void;
    defaultDate?: Date;
}

export const BasicDatePicker = (props: Props) => {
    const { label, callback, defaultDate } = props;
    const [value, setValue] = React.useState<Date | null>(
        defaultDate ? defaultDate : new Date(),
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                    if (callback && newValue) {
                        callback(newValue);
                    }
                }}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="dd/MM/yyyy"
            />
        </LocalizationProvider>
    );
};

export const BasicDateTimePicker = (props: Props) => {
    const { label, callback, defaultDate } = props;
    const [value, setValue] = React.useState<Date | null>(
        defaultDate ? defaultDate : new Date(),
    );
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label={label}
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                    if (callback && newValue) {
                        callback(newValue);
                    }
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
};
