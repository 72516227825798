import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import {
    isValidPhoneNumber,
    parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { RocketLaunchOutlined } from '@mui/icons-material';
import { serverTimestamp } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import CallableServices from '../../services/CallableServices';
import { useAuth } from '../../hooks/useAuth';
import { AuditLogService } from '../../services/AuditLogService';
import { PIPMessage } from '../../types';

interface IContact {
    number?: string;
    appointment_date?: string;
}

interface RawContact {
    [key: string]: string;
}

interface Props {
    onSmsDispatched: () => void;
}

const ContactsUploadContainer = (props: Props) => {
    const { onSmsDispatched } = props;
    const [contacts, setContacts] = React.useState<Array<IContact>>([]);
    const [acceptedFile, setAcceptedFile] = React.useState<File | null>(null);
    const [isAdding, setIsAdding] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const authCtx = useAuth();

    const onDrop = useCallback((acceptedFiles) => {
        setAcceptedFile(acceptedFiles[0]);
        // Do something with the files
        Papa.parse(acceptedFiles[0], {
            header: true,
            complete: (results) => {
                const data = results.data as Array<RawContact>;
                const { fields } = results.meta;
                if (!fields || !fields.length) {
                    return;
                }
                setContacts(
                    data
                        .filter((row) => row.number.trim() !== '')
                        .map((row) => ({
                            number: parsePhoneNumberFromString(
                                row[`${fields[0]}`],
                                'GB',
                            )?.number,
                        })),
                );
            },
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
        },
    });

    const isValidContactList = (data: IContact[]): boolean => {
        const invalid = data.find((c) => !c.number);
        if (invalid) {
            return false;
        }
        return true;
    };

    const allPhoneNumbersAreValidUkFormat = (data: IContact[]): boolean => {
        const invalid = data.find(
            (c) =>
                c.number !== undefined && !isValidPhoneNumber(c.number, 'GB'),
        );
        if (invalid) {
            return false;
        }
        return true;
    };

    const addToContactList = async () => {
        try {
            if (!isValidContactList(contacts)) {
                enqueueSnackbar(
                    'Invalid contact list. One or more of the data points has an undefined phone number. Please correct and reupload',
                    { variant: 'error' },
                );
                return;
            }
            if (!allPhoneNumbersAreValidUkFormat(contacts)) {
                enqueueSnackbar(
                    'Invalid contact list. One or more of the phone numbers is not in a valid UK format. Please correct and reupload. Make sure the phone number starts with 0',
                    { variant: 'error' },
                );
                return;
            }
            setIsAdding(true);
            const promises: Promise<any>[] = [];
            contacts.forEach((c) => {
                promises.push(
                    CallableServices.schedulePIPInvite({
                        phone_number: c.number,
                        customer_id: authCtx.customer_id,
                        customer_name: authCtx.customer_name,
                        created_by: authCtx.user?.email ?? '',
                        created_at: serverTimestamp(),
                        namespace: authCtx.namespace,
                    } as unknown as PIPMessage),
                );
            });
            await Promise.all(promises);
            setIsAdding(false);
            enqueueSnackbar('Contacts added successfully', {
                variant: 'success',
            });
            setContacts([]);
            setAcceptedFile(null);
            AuditLogService.addLog({
                action_type: 'CREATE',
                user: authCtx.user?.email || '',
                description: `SMS Contacts List Upload`,
                role_type: authCtx.is_admin ? 'admin' : 'super',
            });
            if (onSmsDispatched) {
                onSmsDispatched();
            }
        } catch (e) {
            const err = e as Error;
            setIsAdding(false);
            enqueueSnackbar(err.message, { variant: 'error' });
        }
    };

    return (
        <Stack direction="column">
            {acceptedFile === null && (
                <Box p={2}>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div>
                            <img
                                alt="Select file"
                                style={{ width: 130 }}
                                src="/static/undraw_add_file2_gvbb.svg"
                            />
                        </div>
                        {isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : contacts.length ? null : (
                            <p>
                                Drag 'n' drop your csv iles here, or click to
                                select files
                            </p>
                        )}
                    </div>
                </Box>
            )}
            {contacts.length ? (
                <Box mt={2}>
                    <Stack direction="row" spacing={2}>
                        <LoadingButton
                            variant="contained"
                            size="large"
                            onClick={addToContactList}
                            loading={isAdding}
                            fullWidth
                        >
                            Invite Now
                            <RocketLaunchOutlined />
                        </LoadingButton>
                    </Stack>
                    <List
                        dense
                        sx={{
                            height: 400,
                            overflowY: 'scroll',
                        }}
                    >
                        {contacts.map((contact, i) => (
                            <React.Fragment
                                key={`contact_list_${contact.number}_${i}`}
                            >
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                color={
                                                    !contact.number
                                                        ? 'error'
                                                        : 'textPrimary'
                                                }
                                            >
                                                Phone Number:{' '}
                                                {contact.number ||
                                                    'invalid phone number'}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            ) : null}
        </Stack>
    );
};

export default ContactsUploadContainer;
