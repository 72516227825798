import React from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { ExitToApp } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { Avatar, Chip, Stack, Tooltip } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { currentEnvironmentFromUrl } from '../../utils/envUtil';
import { drawerWidth } from './SideDrawer';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

interface Props {
    open: boolean;
    onToggleDrawer: () => void;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const TopBar = (props: Props) => {
    const { open, onToggleDrawer } = props;

    const auth = useAuth();
    const navigate = useNavigate();

    const handleLogoutEvent = React.useCallback(() => {
        (async () => {
            if (!auth || !auth.handleLogout) {
                return;
            }
            await auth.handleLogout();
            navigate('/');
        })();
        // eslint-disable-next-line
    }, [auth]);

    return (
        <AppBar position="absolute" open={open}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onToggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Dashboard
                </Typography>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{ alignItems: 'center' }}
                >
                    {currentEnvironmentFromUrl(window.location.href) ===
                        'dev' && (
                        <Chip
                            variant="outlined"
                            label="Staging"
                            sx={{ color: '#fff', background: 'red' }}
                        />
                    )}
                    <Chip
                        variant="outlined"
                        label={auth?.user?.email}
                        sx={{ color: '#fff' }}
                        avatar={<Avatar {...stringAvatar('Admin User')} />}
                    />
                    <Tooltip title="Logout">
                        <IconButton
                            aria-label="logout"
                            onClick={handleLogoutEvent}
                        >
                            <ExitToApp sx={{ color: '#fff' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
