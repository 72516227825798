import React from 'react';
import MaterialTable from 'material-table';
import { Box, Stack, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import {
    QueryConstraint,
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    where,
} from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { db } from '../../config/FirebaseConfig';
import useConfirm from '../../hooks/useConfirm';
import { PIPMessage } from '../../types';
import { useAuth } from '../../hooks/useAuth';
import CallableServices from '../../services/CallableServices';

interface PIPContact extends PIPMessage {
    id: string;
    status: 'sent' | 'failed' | 'queued' | 'pending' | 'cancelled';
}

const statusLabels = [
    {
        value: 'sent',
        label: 'Sent',
        icon: <CheckBoxIcon sx={{ color: 'green' }} />,
    },
    {
        value: 'cancelled',
        label: 'Cancelled',
        icon: <CloseIcon sx={{ color: 'red' }} />,
    },
    {
        value: 'failed',
        label: 'Failed',
        icon: <CloseIcon sx={{ color: 'red' }} />,
    },
    {
        value: 'pending',
        label: 'Pending',
        icon: <PendingActionsIcon />,
    },
];
const supplementaryInfo: { [key: string]: string } = {
    activity_12: 'Activity 12',
    aids_and_assistance: 'Aids and Assistance',
    activity_9: ' Activity 9',
    prompts_and_supervision: 'Prompts and Supervision',
    activity_11: 'Activity 11',
};

const SmsContactsList = () => {
    const [inviteList, setInviteList] = React.useState<Array<PIPContact>>([]);
    const [loading, setLoading] = React.useState(true);

    const { isConfirmed } = useConfirm();

    const authCtx = useAuth();

    const { enqueueSnackbar } = useSnackbar();

    const getInviteLogs = async () => {
        try {
            const ref = collection(db, 'invite_logs');
            const constraints: QueryConstraint[] = [
                orderBy('created_at', 'desc'),
                limit(300),
            ];

            if (!authCtx.is_internal) {
                constraints.unshift(
                    where('customer_id', '==', authCtx.customer_id || ''),
                );
            }

            const q = query(ref, ...constraints);
            const snapshots = await getDocs(q);
            const data: any[] = [];
            snapshots.forEach((snapshot) => {
                data.push({ ...snapshot.data(), id: snapshot.id });
            });
            setInviteList(data);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const removeData = async (rows: PIPContact[] | PIPContact) => {
        if (!Array.isArray(rows)) {
            await CallableServices.cancelPIPInvite(rows.id);
        } else {
            const promises = rows.map((row) => {
                return CallableServices.cancelPIPInvite(row.id);
            });
            await Promise.all(promises);
        }
        await getInviteLogs();
        enqueueSnackbar('Invitation cancelled', { variant: 'success' });
    };

    React.useEffect(() => {
        (async () => {
            await getInviteLogs();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ThemeProvider theme={createTheme()}>
            <>
                <MaterialTable
                    data={inviteList}
                    columns={[
                        { title: 'Phone Number', field: 'phone_number' },
                        {
                            title: 'Information Requested',
                            field: 'supplementary_info',
                            render: (rowData) => {
                                if (rowData && rowData.supplementary_info) {
                                    const str = Object.keys(
                                        rowData.supplementary_info,
                                    )
                                        // eslint-disable-next-line array-callback-return
                                        .map((key) => {
                                            if (
                                                rowData.supplementary_info[
                                                    key
                                                ] === true
                                            ) {
                                                return supplementaryInfo[key];
                                            }
                                        })
                                        .filter((item) => item !== undefined)
                                        .join(', ');
                                    return str;
                                }
                                return '';
                            },
                        },
                        { title: 'Created by', field: 'created_by' },
                        {
                            title: 'Created Date',
                            field: 'created_at',
                            type: 'datetime',
                            render: (rowData) =>
                                rowData?.created_at?.toDate().toLocaleString(),
                        },
                        {
                            title: 'Sent Date', // Maybe change this to delivery date?
                            field: 'delivery_date',
                            type: 'datetime',
                            render: (rowData) => {
                                if (
                                    rowData?.status === 'sent' &&
                                    rowData?.delivery_date
                                ) {
                                    return rowData?.delivery_date
                                        ?.toDate()
                                        .toLocaleString();
                                }
                                return '';
                            },
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            render: (rowData) => {
                                const status = statusLabels.find(
                                    (item) => item.value === rowData.status,
                                );
                                return (
                                    <Stack direction="row" spacing={1}>
                                        <Box>{status?.label}</Box>
                                        <Box>{status?.icon}</Box>
                                    </Stack>
                                );
                            },
                        },
                    ]}
                    title="Invite Log"
                    options={{
                        searchFieldVariant: 'outlined',
                        pageSize: 20,
                        pageSizeOptions: [20, 40, 60],
                        actionsCellStyle: {
                            minWidth: '88px',
                            paddingLeft: '24px',
                            paddingRight: '24px',
                        },
                    }}
                    actions={[
                        (rowData) => ({
                            tooltip: 'Cancel invitation',
                            icon: 'cancel',
                            onClick: async (evt, data) => {
                                const confirm = await isConfirmed(
                                    'Are you sure you want to cancel this invitation?',
                                );
                                if (!confirm) {
                                    return;
                                }
                                await removeData(data);
                            },
                            hidden: rowData?.status !== 'pending',
                        }),
                    ]}
                />
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        </ThemeProvider>
    );
};

export default SmsContactsList;
