import { useEffect, useState } from 'react';
import { Assessment } from '../types';
import { Box, CircularProgress } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/FirebaseConfig';

interface DobHackComponentProps {
    data: Assessment;
}

const DobHackComponent = (props: DobHackComponentProps) => {
    const [loading, setLoading] = useState(true);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const { data } = props;
    useEffect(() => {
        (async () => {
            try {
                const ref = doc(db, 'users', data.account_id);
                const user = await getDoc(ref);
                if (user.exists()) {
                    setDateOfBirth(
                        new Date(
                            user.data()?.date_of_birth?.split('T')[0],
                        ).toLocaleDateString(),
                    );
                }
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {loading ? (
                <CircularProgress size={20} />
            ) : (
                <span>{dateOfBirth}</span>
            )}
        </Box>
    );
};

export default DobHackComponent;
