import { Content } from 'pdfmake/interfaces';
import SectionTitle from '../../../components/SectionTitle';
import { pdfStylingVars } from '../../../pdfStyle';

export default function Questionnaire(): Content {
    // Style
    const { spacingMedium } = pdfStylingVars.spacing;

    return {
        // After this component, there will be a page break
        pageBreak: 'after',
        stack: [
            SectionTitle({
                header: '01: Questionnaire',
                subheader: 'ACTION: SELECT RADIO BUTTONS'
            }),
            {
                margin: [0, spacingMedium, 0, 0],
                table: {
                    widths: [140, '*', '*'],
                    body: [
                        [
                            {
                                text: '\nPHYSICAL',
                                bold: true,
                                rowSpan: 5,
                            },
                            'LOWER LIMB',
                            {
                                text: '\nSELECT UNCLEAR',
                                rowSpan: 5,
                            },
                        ],
                        [
                            {},
                            'UPPER LIMB',
                            {},
                        ],
                        [
                            {},
                            'VISION, SPEECH, HEARING',
                            {},
                        ],
                        [
                            {},
                            'CONTINENCE',
                            {},
                        ],
                        [
                            {},
                            'CONSCIOUSNESS',
                            {},
                        ],
                    ],
                },
                layout: 'default',
            },
            {
                margin: [0, spacingMedium, 0, 0],
                table: {
                    widths: [140, '*', '*'],
                    body: [
                        [
                            {
                                text: '\nMENTAL HEALTH',
                                bold: true,
                                rowSpan: 3,
                            },
                            'UNDERSTANDING AND FOCUS',
                            {
                                text: '\nSELECT UNCLEAR',
                                rowSpan: 3,
                            },
                        ],
                        [
                            {},
                            'ADAPTING TO CHANGE',
                            {},
                        ],
                        [
                            {},
                            'SOCIAL INTERACTION',
                            {},
                        ],
                    ],
                },
                layout: 'default',
            },
            {
                margin: [0, spacingMedium, 0, 0],
                bold: true,
                text: 'NO. OF BUTTONS TO SELECT: 8',
            },
        ],
    };
}
