import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { functions } from '../config/FirebaseConfig';
import { Timestamp } from 'firebase/firestore';

interface ChatFlowData {
    chatFlowData: Array<{
        id: string;
        version_label: string;
        current_version: number;
        name: string;
        created_by: string;
        created_at: Timestamp;
    }>;
}

interface FlowReq {
    id: string;
    version_string: string;
}

interface FlowResponse {
    status: number;
    message?: string;
    flow: any[];
}
/**
 * Lists all the chat flows
 * @returns {Promise<Array<object>>} - The response object
 */
export const listFlows = async (): Promise<
    HttpsCallableResult<ChatFlowData>
> => {
    return httpsCallable<unknown, ChatFlowData>(functions, 'bot-list')();
};

/**
 *
 * @param {object} req - The request object
 * @return {Promise<object>} - The response object
 */
export const getFlow = async (
    req: FlowReq,
): Promise<HttpsCallableResult<FlowResponse>> => {
    return httpsCallable<FlowReq, FlowResponse>(
        functions,
        'bot-getDialogFlow',
    )(req);
};
