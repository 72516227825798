import { Content } from 'pdfmake/interfaces';
import { pdfStylingVars } from '../pdfStyle';

interface Data {
    header: string;
    subheader?: string;
    enableMarginTop?: boolean;
}

export default function SectionTitle(data: Data): Content {
    const { header, subheader, enableMarginTop = true } = data;
    // Style
    const { spacingLarge } = pdfStylingVars.spacing;

    return {
        margin: [0, enableMarginTop ? spacingLarge : 0, 0, 0],
        table: {
            widths: '*',
            body: [
                [
                    {
                        style: 'headerCell',
                        stack: [
                            {
                                text: header,
                                style: 'heading4',
                            },
                            {
                                text: subheader || '',
                                style: 'subtitle1',
                            },
                        ],
                    },
                ],
            ],
        },
        layout: 'default',
    };
}
