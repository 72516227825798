import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useFormik } from 'formik';
import DOMPurify from 'dompurify';
import { object, string } from 'yup';
import CallableServices from '../../services/CallableServices';
import { AdminUser } from '../../types';
import { LoadingButton } from '@mui/lab';
import { auth } from '../../config/FirebaseConfig';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../hooks/useAuth';
import { currentEnvironmentFromUrl } from '../../utils/envUtil';
import CustomerSelector from '../customers/CustomerSelector';

interface Props {
    open: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ROLES = [
    { value: 'Super', label: 'Super' },
    { value: 'Admin', label: 'Admin' },
];

const ACCESS_TYPES = [
    { value: 'AD', label: 'Azure' },
    { value: 'FB', label: 'EQL' },
];

const registrationSchema = object({
    first_name: string()
        .min(2, 'Too short')
        .max(50, 'Too long')
        .required('First name is required'),
    last_name: string()
        .min(2, 'Too short')
        .max(50, 'Too long')
        .required('Last name is required'),
    email: string()
        .email('Invalid email')
        .required('Email address is required'),
    role_type: string().required('Role is required'),
    access_type: string().required('Access type is required'),
    customer_id: string().required('Customer is required'),
    customer_name: string().required('Customer name is required'),
});

export default function AddUserDialog(props: Props) {
    const { open, onClose } = props;
    const { enqueueSnackbar } = useSnackbar();

    const authObj = useAuth();

    const [loading, setLoading] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            role_type: 'Admin',
            access_type: 'FB',
            customer_id: authObj.customer_id,
            customer_name: authObj.customer_name,
        },

        validationSchema: registrationSchema,
        validateOnMount: true,
        onSubmit: async (values) => {
            try {
                if (authObj?.is_internal && !values.customer_id) {
                    formik.setErrors({
                        customer_id: 'Customer is required',
                    });
                    return;
                }
                setLoading(true);
                const { first_name, last_name, email, role_type, access_type } =
                    values;
                const response = await CallableServices.addAdminUser({
                    first_name: DOMPurify.sanitize(first_name),
                    last_name: DOMPurify.sanitize(last_name),
                    email: DOMPurify.sanitize(email),
                    role_type,
                    access_type,
                    customer_id: values.customer_id,
                    customer_name: values.customer_name,
                    namespace: values.customer_name.toLowerCase().match('chda')
                        ? 'CHDA'
                        : 'PIP',
                } as AdminUser);
                const { status, message } = response.data;
                const accessType = values.access_type;
                if (status !== 200) {
                    enqueueSnackbar(message, { variant: 'error' });
                    setLoading(false);
                    return;
                }
                if (accessType === 'AD') {
                    enqueueSnackbar(
                        'User with Azure Directory login successfully created',
                        { variant: 'success' },
                    );
                    setLoading(false);
                    onClose();
                    return;
                }
                const curEnv = currentEnvironmentFromUrl(window.location.href);
                const url =
                    curEnv === 'dev'
                        ? 'https://pat-admin.web.app/'
                        : 'https://admin.infoshare.uk/';
                await sendPasswordResetEmail(auth, values.email, {
                    url,
                });
                enqueueSnackbar(
                    `User successfully created ${
                        values.access_type === 'FB'
                            ? `an email has been sent to the user ${values.email}`
                            : ''
                    }`,
                    { variant: 'success' },
                );
                setLoading(false);
                // reset state
                onClose();
            } catch (e) {
                const error = e as Error;
                console.error(e);
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        },
    });

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Enter User Details</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Stack spacing={2} sx={{ mt: 4, mb: 4 }}>
                        <TextField
                            variant="outlined"
                            label="First name"
                            fullWidth
                            id="first_name"
                            error={
                                formik.touched.first_name &&
                                Boolean(formik.errors.first_name)
                            }
                            helperText={
                                formik.touched.first_name &&
                                formik.errors.first_name
                            }
                            required
                            {...formik.getFieldProps('first_name')}
                        />
                        <TextField
                            variant="outlined"
                            label="Last name"
                            fullWidth
                            id="last_name"
                            error={
                                formik.touched.last_name &&
                                Boolean(formik.errors.last_name)
                            }
                            helperText={
                                formik.touched.last_name &&
                                formik.errors.last_name
                            }
                            required
                            {...formik.getFieldProps('last_name')}
                        />
                        <TextField
                            variant="outlined"
                            label="Email"
                            fullWidth
                            id="email"
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                            required
                            {...formik.getFieldProps('email')}
                        />
                    </Stack>
                    <Stack sx={{ mt: 2, mb: 4 }} direction="row" spacing={3}>
                        <Box sx={{ minWidth: 180 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Role Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formik.values.role_type}
                                    label="Access Type"
                                    onChange={formik.handleChange}
                                    sx={{ borderColor: 'red' }}
                                    name="role_type"
                                    error={
                                        formik.touched.role_type &&
                                        Boolean(formik.errors.role_type)
                                    }
                                >
                                    {ROLES.map((option) => (
                                        <MenuItem
                                            key={option.label}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ minWidth: 180 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label-2">
                                    Login Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label-2"
                                    id="demo-simple-select-2"
                                    value={formik.values.access_type}
                                    label="Login Type"
                                    name="access_type"
                                    onChange={formik.handleChange}
                                    sx={{ borderColor: 'red' }}
                                    error={
                                        formik.touched.access_type &&
                                        Boolean(formik.errors.access_type)
                                    }
                                >
                                    {ACCESS_TYPES.map((option) => (
                                        <MenuItem
                                            key={option.label}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        {authObj && authObj.is_internal && (
                            <Box sx={{ minWidth: 220 }}>
                                <CustomerSelector
                                    onChange={(customer) => {
                                        formik.setFieldValue(
                                            'customer_id',
                                            customer.customerId,
                                        );
                                        formik.setFieldValue(
                                            'customer_name',
                                            customer.customerName,
                                        );
                                    }}
                                />
                            </Box>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ mr: 2 }}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                onClose();
                            }}
                            sx={{ mr: 2 }}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            variant="contained"
                            disabled={
                                Boolean(formik.errors.email) ||
                                Boolean(formik.errors.first_name) ||
                                Boolean(formik.errors.last_name)
                            }
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
}
