import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Timestamp } from '@firebase/firestore';

export type Flow = {
    id: string;
    version_label: string;
    current_version: number;
    name: string;
    created_by: string;
    created_at: Timestamp;
};

interface Props {
    flowList: Flow[];
    onChange: (flow: {
        flowId: string;
        versionLabel: string;
        flowName: string;
    }) => void;
    required: boolean;
    defaultValue?: any;
}

const BotSelector = (props: Props) => {
    const { onChange, required, defaultValue, flowList } = props;

    const [flow, setFlow] = React.useState<string | null>(defaultValue || null);

    const handleChange = (event: SelectChangeEvent) => {
        const selectedFlow = flowList.find(
            (flow) => flow.id === event.target.value,
        );
        setFlow(selectedFlow?.id ?? null);
        onChange({
            flowId: selectedFlow?.id ?? '',
            versionLabel: selectedFlow?.version_label ?? '',
            flowName: selectedFlow?.name ?? '',
        });
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="flow-simple-select-label" required={required}>
                    Bot
                </InputLabel>
                <Select
                    labelId="flow-simple-select-label"
                    id="flow-simple-select"
                    value={flow ?? ''}
                    label="Bot"
                    onChange={handleChange}
                >
                    {flowList.map((flow) => (
                        <MenuItem key={flow.id} value={flow.id}>
                            {flow.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default BotSelector;
