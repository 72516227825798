import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { mixed, object, string } from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    Paper,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import BotSelector, { Flow } from './BotSelector';
import LogoUpload from '../../components/LogoUpload';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';
import { Customer } from '../../services/customerServices';
import uploadImages from '../../services/uploadImages';
import { listFlows } from '../../services/bot';

const MAX_FILE_SIZE = 204800;
export const specialChars = /^[aA-zZ0-9\s]+$/;

const setupSchema = object({
    name: string()
        .matches(specialChars, 'Please do not use special characters')
        .required('Name is required'),
    email: string()
        .email('Invalid email')
        .required('Email address is required'),
    question_set_config: object({
        bot_id: string().required('Bot id is required'),
        version_label: string().required('Version label is required'),
        bot_name: string().required('Bot name is required'),
    }),
    sms_config: object({
        sender_label: string()
            .max(11, 'Sender name can be up to 11 characters long')
            .required('Sender label is required'),
    }),
    logo_light: mixed().when({
        is: (exists: any) => !!exists,
        then: (rule) =>
            rule
                .test(
                    'is-valid-size',
                    'This file is too big. Please upload a file smaller than 200KB',
                    (value) => value && value.size <= MAX_FILE_SIZE,
                )
                .required('Field is required'),
        otherwise: mixed(),
    }),
    logo_dark: mixed().when({
        is: (exists: any) => !!exists,
        then: (rule) =>
            rule
                .test(
                    'is-valid-size',
                    'This file is too big. Please upload a file smaller than 200KB',
                    (value) => value && value.size <= MAX_FILE_SIZE,
                )
                .required('Field is required'),
        otherwise: mixed(),
    }),
});

const CustomerSettings = () => {
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [chatFlowLoading, setChatFlowLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [flowList, setFlowList] = useState<Array<Flow>>([]);

    const {
        name,
        email,
        active,
        logo_light,
        logo_dark,
        question_set_config,
        sms_config,
    } = customer || {};

    const { bot_id, version_label, bot_name } = question_set_config || {};
    const { sender_label } = sms_config || {};

    const params = useParams();
    const { id } = params;

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const getCustomerById = async (customerId: string) => {
        try {
            setLoading(true);

            const ref = doc(db, 'customers', customerId);
            const currentCustomer = await getDoc(ref);
            const currentCustomerData = {
                ...currentCustomer.data(),
                id: currentCustomer.id,
            } as Customer;
            setCustomer(currentCustomerData);
        } catch (e) {
            console.log(e);
            enqueueSnackbar(
                'There was some issues with loading customer data. Please try again later.',
                { variant: 'error' },
            );
        } finally {
            setLoading(false);
        }
    };

    const getChatFlowList = async () => {
        setChatFlowLoading(true);
        try {
            const botList = await listFlows();
            setFlowList(botList.data.chatFlowData);
        } catch (e) {
            console.error(e);
        } finally {
            setChatFlowLoading(false);
        }
    };

    const updateCustomer = async (values: Customer, logoUrls: any) => {
        const { logo_light, logo_dark, ...rest } = values;
        if (!id) {
            return;
        }

        const ref = doc(db, 'customers', id);
        const document = await getDoc(ref);
        if (document.exists()) {
            await updateDoc(ref, {
                ...rest,
                ...logoUrls,
                updated_at: serverTimestamp(),
            });
            return;
        }
    };

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name || '',
            email: email || '',
            active: active || false,
            logo_light: undefined,
            logo_dark: undefined,
            question_set_config: {
                bot_id: bot_id || '',
                version_label: version_label || '',
                bot_name: bot_name || '',
            },
            sms_config: {
                sender_label: sender_label || '',
            },
        },
        validationSchema: setupSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: async (values) => {
            try {
                if (!id) return;
                setSubmitting(true);
                let logoUrls = {};
                if (values.logo_light && values.logo_dark) {
                    logoUrls = await uploadImages(
                        [
                            {
                                file: values.logo_light,
                                fileName: 'logo_light',
                            },
                            {
                                file: values.logo_dark,
                                fileName: 'logo_dark',
                            },
                        ],
                        id,
                    );
                }
                if (values.logo_light && !values.logo_dark) {
                    logoUrls = await uploadImages(
                        [
                            {
                                file: values.logo_light,
                                fileName: 'logo_light',
                            },
                        ],
                        id,
                    );
                }
                if (!values.logo_light && values.logo_dark) {
                    logoUrls = await uploadImages(
                        [
                            {
                                file: values.logo_dark,
                                fileName: 'logo_dark',
                            },
                        ],
                        id,
                    );
                }
                await updateCustomer(values, logoUrls);
                enqueueSnackbar('Customer updated successfully', {
                    variant: 'success',
                });
                navigate('/dashboard');
            } catch (e) {
                console.error(e);
                const err = e as Error;
                enqueueSnackbar(err.message, { variant: 'error' });
            } finally {
                setSubmitting(false);
            }
        },
    });

    const isFormValid = () => {
        if (
            (!logo_light && !formik.values.logo_light) ||
            (!logo_dark && !formik.values.logo_dark)
        ) {
            return false;
        } else return formik.isValid;
    };

    useEffect(() => {
        if (!id) {
            return;
        }
        getCustomerById(id);
        getChatFlowList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            {loading || chatFlowLoading ? (
                <Backdrop open sx={{ color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <Typography
                        variant="h4"
                        component="h1"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            marginTop: '16px',
                        }}
                    >
                        Customer Settings
                    </Typography>
                    <Typography
                        variant="h6"
                        component="h2"
                        gutterBottom
                        sx={{
                            marginTop: '16px',
                            color: 'grey',
                        }}
                    >
                        Customer UID: {id}
                    </Typography>
                    <Paper sx={{ p: 2 }}>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                            >
                                Details
                            </Typography>
                            <Divider />
                            <Stack spacing={2} sx={{ mt: 2, mb: 4 }}>
                                <TextField
                                    variant="outlined"
                                    label="Customer Name"
                                    fullWidth
                                    id="name"
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    required
                                    {...formik.getFieldProps('name')}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Contact Email Address"
                                    fullWidth
                                    id="email"
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                    required
                                    {...formik.getFieldProps('email')}
                                />
                            </Stack>
                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                                sx={{
                                    marginTop: '16px',
                                }}
                            >
                                Customer Status
                            </Typography>
                            <Divider />
                            <FormGroup
                                sx={{
                                    marginTop: '8px',
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            type="checkbox"
                                            name="active"
                                            onChange={formik.handleChange}
                                            checked={formik.values.active}
                                        />
                                    }
                                    label={
                                        formik.values.active
                                            ? 'Active'
                                            : 'Inactive'
                                    }
                                />
                            </FormGroup>
                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                                sx={{
                                    marginTop: '16px',
                                }}
                            >
                                Chat Flow
                            </Typography>
                            <Divider />
                            <Stack spacing={2} sx={{ mt: 2, mb: 4 }}>
                                <BotSelector
                                    flowList={flowList}
                                    onChange={(selectedFlow) => {
                                        formik.setFieldValue(
                                            'question_set_config',
                                            {
                                                bot_id: selectedFlow.flowId,
                                                version_label:
                                                    selectedFlow.versionLabel,
                                                bot_name: selectedFlow.flowName,
                                            },
                                        );
                                    }}
                                    defaultValue={bot_id}
                                    required
                                />
                            </Stack>
                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                                sx={{
                                    marginTop: '16px',
                                }}
                            >
                                Light Logo*
                            </Typography>
                            <Divider />
                            <Box pt={2}>
                                <LogoUpload
                                    formik={formik}
                                    fieldName="logo_light"
                                    previewBackground="#424c5f"
                                    logoSizes={[{ base: '34px', sm: '42px' }]}
                                    error={
                                        formik.values.logo_light &&
                                        formik.errors.logo_light
                                    }
                                    customerLogoUrl={logo_light}
                                    required={!logo_light}
                                />
                            </Box>
                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                                sx={{
                                    marginTop: '16px',
                                }}
                            >
                                Dark Logo*
                            </Typography>
                            <Divider />
                            <Box pt={2}>
                                <LogoUpload
                                    formik={formik}
                                    fieldName="logo_dark"
                                    error={
                                        formik.values.logo_dark &&
                                        formik.errors.logo_dark
                                    }
                                    customerLogoUrl={logo_dark}
                                    required={!logo_dark}
                                />
                            </Box>
                            <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                                sx={{
                                    marginTop: '16px',
                                }}
                            >
                                SMS
                            </Typography>
                            <Divider />
                            <Stack spacing={2} sx={{ mt: 2, mb: 4 }}>
                                <TextField
                                    variant="outlined"
                                    label="SMS Outbound From: Name"
                                    fullWidth
                                    id="sms_config.sender_label"
                                    error={
                                        formik.touched.sms_config
                                            ?.sender_label &&
                                        Boolean(
                                            formik.errors.sms_config
                                                ?.sender_label,
                                        )
                                    }
                                    helperText={
                                        formik.touched.sms_config
                                            ?.sender_label &&
                                        formik.errors.sms_config?.sender_label
                                    }
                                    required
                                    {...formik.getFieldProps(
                                        'sms_config.sender_label',
                                    )}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Box />
                                <Box>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            navigate('/dashboard');
                                        }}
                                        sx={{ mr: 2 }}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        loading={submitting}
                                        variant="contained"
                                        type="submit"
                                        disabled={submitting || !isFormValid()}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        </form>
                    </Paper>
                </>
            )}
        </>
    );
};

export default CustomerSettings;
