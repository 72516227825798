import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@mui/material';
import AssessmentList from './containers/AssessmentList';
import DashboardLayout from './layouts/DashboardLayout';
import theme from './theme';
import { AuthenticationContext as AuthProvider } from './authentication/AuthenticationContext';
import { UserList } from './containers/users/Users';
import Questionnaire from './containers/Questionnaire';
import ConfirmContextProvider from './contexts/ConfirmContextProvider';
import ConfirmDialog from './components/ConfirmDialog';
import AuditLogs from './containers/AuditLogs';
import { useAuth } from './hooks/useAuth';
import { ResetPassword } from './containers/ResetPassword';
import ForgotPassword from './containers/ForgotPassword';
import SendSMS from './containers/sms/SendSMS';
import MessagesList from './containers/messages/MessagesList';
import CustomerList from './containers/customers/CustomerList';
import CustomerSettings from './containers/customers/CustomerSettings';
import SystemUsersList from './containers/users/SystemUserList';
import { InternalViewForUsers } from './containers/users/InternalViewForUsers';
import RoleControlled from './routes/RoleControlled';
import AuthRequired from './routes/AuthRequired';
import NamespaceControlled from './routes/NamespaceControlled';
import PipAssessmentList from './containers/PipAssessmentList';
import SignInPasswordAndEmail from './containers/SignInPasswordAndEmail';
import MiContainer from './containers/MiContainer';

const Main = () => {
    const authCtx = useAuth();

    return (
        <RouterProvider
            router={createBrowserRouter([
                {
                    path: '/',
                    index: true,
                    element: !authCtx.user ? (
                        <SignInPasswordAndEmail />
                    ) : (
                        <Navigate to="dashboard" replace />
                    ),
                },
                { path: '/reset-password', element: <ResetPassword /> },
                { path: '/forgot-password', element: <ForgotPassword /> },
                {
                    path: 'dashboard',
                    element: (
                        <AuthRequired>
                            <DashboardLayout />
                        </AuthRequired>
                    ),
                    children: [
                        {
                            index:
                                authCtx.is_internal === false &&
                                authCtx.namespace === 'CHDA',
                            element: (
                                <NamespaceControlled namespace="CHDA">
                                    <AssessmentList />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            index:
                                authCtx.is_internal === false &&
                                authCtx.namespace === 'PIP',
                            element: (
                                <NamespaceControlled namespace="PIP">
                                    <PipAssessmentList />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            path: 'management-information',
                            element: (
                                <NamespaceControlled namespace="CHDA">
                                    <MiContainer />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            path: 'reports',
                            children: [
                                {
                                    path: 'pip',
                                    element: (
                                        <NamespaceControlled namespace="PIP">
                                            <PipAssessmentList />
                                        </NamespaceControlled>
                                    ),
                                },
                                {
                                    path: 'chda',
                                    element: (
                                        <NamespaceControlled namespace="CHDA">
                                            <AssessmentList />
                                        </NamespaceControlled>
                                    ),
                                },
                            ],
                        },
                        {
                            path: 'users',
                            element: (
                                <RoleControlled role={['super']}>
                                    <UserList />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'logs',
                            element: (
                                <RoleControlled
                                    role={[
                                        'super',
                                        'system_admin',
                                        'system_user',
                                    ]}
                                >
                                    <AuditLogs />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'store',
                            element: (
                                <RoleControlled
                                    role={['system_admin', 'system_user']}
                                >
                                    <Questionnaire />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'sms',
                            element: (
                                <NamespaceControlled namespace="CHDA">
                                    <SendSMS />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            path: 'messages',
                            element: (
                                <NamespaceControlled namespace="PIP">
                                    <MessagesList />
                                </NamespaceControlled>
                            ),
                        },
                        {
                            index: authCtx.is_internal === true,
                            element: (
                                <RoleControlled
                                    role={['system_admin', 'system_user']}
                                >
                                    <CustomerList />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'customers/:id',
                            element: (
                                <RoleControlled
                                    role={['system_admin', 'system_user']}
                                >
                                    <CustomerSettings />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'system',
                            element: (
                                <RoleControlled role={['system_admin']}>
                                    <SystemUsersList />
                                </RoleControlled>
                            ),
                        },
                        {
                            path: 'user-management',
                            element: (
                                <RoleControlled
                                    role={['system_admin', 'system_user']}
                                >
                                    <InternalViewForUsers />
                                </RoleControlled>
                            ),
                        },
                    ],
                },
            ])}
        />
    );
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
                <AuthProvider>
                    <ConfirmContextProvider>
                        <Main />
                        <ConfirmDialog />
                    </ConfirmContextProvider>
                </AuthProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
