import { Content } from 'pdfmake/interfaces';
import { pdfStylingVars } from '../pdfStyle';
import { Assessment } from '../../types';

interface Data {
    reportTitle: string;
    patient: Assessment;
}

export default function PatientInfoHeader(data: Data): Content {
    const { reportTitle, patient } = data;
    // Style
    const { colors, spacing } = pdfStylingVars;
    const { spacingMedium } = spacing;

    return {
        columns: [
            // Column 1
            {
                text: reportTitle,
                style: 'heading1',
                margin: [0, 15, 0, 0],
                width: '*', // Width that occupies the remaining space
            },
            // Column 2
            {
                bold: true,
                alignment: 'right',
                color: colors.bodyTextLighter,
                width: 'auto', // Width based on content
                stack: [
                    {
                        text: `${patient.first_name} ${patient.last_name}`,
                        style: 'heading4',
                        color: colors.bodyText,
                    },
                    `NINO: ${patient.national_insurance_number}`,
                    `Date of Birth: ${patient.date_of_birth}`,
                    `Postcode: ${patient.postcode || 'NIL'}`,
                    `Date of Assessment: ${patient.date_of_assessment
                        .toDate()
                        .toLocaleDateString()}`,
                    {
                        text: `UID: ${patient.id}`,
                        margin: [0, spacingMedium, 0, 0],
                    },
                ],
            },
        ],
    };
}
