import React, { createContext, useState } from 'react';

export const ConfirmContext = createContext<any>({});

const ConfirmContextProvider: React.FC = ({ children }) => {
    const [confirm, setConfirm] = useState({
        prompt: '',
        isOpen: false,
        proceed: null,
        cancel: null,
    });

    return (
        <ConfirmContext.Provider value={[ confirm, setConfirm]}>
            {children}
        </ConfirmContext.Provider>
    );
};
export default ConfirmContextProvider;
