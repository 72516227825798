import {
    getDatabase,
    ref,
    set,
    serverTimestamp,
    onValue,
    onDisconnect,
} from 'firebase/database';
import {
    doc,
    setDoc,
    serverTimestamp as firestoreTimestamp,
} from 'firebase/firestore';
import { app, auth, db } from '../config/FirebaseConfig';

class PresenceMonitoring {
    monitor() {
        const uid = auth.currentUser?.uid;
        if (!uid) {
            return;
        }
        const database = getDatabase(app);
        const userStatusDatabaseRef = ref(database, `/status/${uid}`);

        const userStatusFirestoreRef = doc(db, `/status/${uid}`);

        // Realtime database state
        const isOfflineForDatabase = {
            state: 'offline',
            last_changed: serverTimestamp(),
        };

        const isOnlineForDatabase = {
            state: 'online',
            last_changed: serverTimestamp(),
        };

        // Firestore state
        const isOfflineForFirestore = {
            state: 'offline',
            last_changed: firestoreTimestamp(),
        };

        const isOnlineForFirestore = {
            state: 'online',
            last_changed: firestoreTimestamp(),
        };

        // Create a reference to the special '.info/connected' path in
        // Realtime Database. This path returns `true` when connected
        // and `false` when disconnected.
        const connectionRef = ref(database, '.info/connected');
        onValue(connectionRef, (snapshot) => {
            // If we're not currently connected, don't do anything
            if (!snapshot.val()) {
                setDoc(userStatusFirestoreRef, isOfflineForFirestore);
                return;
            }
            // If we are currently connected, then use the 'onDisconnect()'
            // method to add a set which will only trigger once this
            // client has disconnected by closing the app,
            // losing internet, or any other means.
            onDisconnect(userStatusDatabaseRef)
                .set(isOfflineForDatabase)
                .then(() => {
                    // The promise returned from .onDisconnect().set() will
                    // resolve as soon as the server acknowledges the onDisconnect()
                    // request, NOT once we've actually disconnected:
                    // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
                    // We can now safely set ourselves as 'online' knowing that the
                    // server will mark us as offline once we lose connection.
                    set(userStatusDatabaseRef, isOnlineForDatabase);
                    setDoc(userStatusFirestoreRef, isOnlineForFirestore);
                });
        });
        window.localStorage.removeItem('uid');
    }
}

export default PresenceMonitoring;
