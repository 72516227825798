import React, { useState, useCallback } from 'react';
import {
    Box,
    Stack,
    Backdrop,
    CircularProgress,
    IconButton,
    createTheme,
    Checkbox,
} from '@mui/material';
import {
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    QueryConstraint,
    where,
} from 'firebase/firestore';
import MaterialTable from 'material-table';
import { endOfDay, startOfDay } from 'date-fns';
import { useSnackbar } from 'notistack';
import { ThemeProvider } from '@mui/styles';
import { db } from '../config/FirebaseConfig';
import { BasicDatePicker } from '../components/DatePicker';
import BasicSelect from '../components/BasicSelect';
import type { Assessment, StructuredTranscript } from '../types';
import { PictureAsPdfSharp } from '@mui/icons-material';
import TranscriptViewer from './TranscriptViewer';
import { useAuth } from '../hooks/useAuth';
import { reviewAssessment } from '../services/assessmentServices';
import { downloadPdfReport, infoShareReportPdf } from '../services/pdfService';
import DobHackComponent from '../components/DobHackComponent';

const defaultMaterialTheme = createTheme();

const AssessmentList = () => {
    const { enqueueSnackbar } = useSnackbar();

    const auth = useAuth();

    const [loading, setLoading] = useState(false);
    const [transcript, setTranscript] = useState<StructuredTranscript[]>([]);
    const [filter, setFilter] = useState<{
        from: Date;
        to: Date;
        status: string;
        reviewed: 'Reviewed' | 'Not Reviewed' | 'All' | string;
    }>({
        from: startOfDay(new Date()),
        to: endOfDay(new Date()),
        status: 'completed',
        reviewed: 'Not Reviewed',
    });

    const [assessmentList, setAssessmentList] = useState<Assessment[]>([]);
    const [currentAssessment, setCurrentAssessment] =
        useState<Assessment | null>(null);

    const handleFromDateCallback = (date: Date) => {
        setFilter({
            ...filter,
            from: startOfDay(date),
        });
    };

    const handleToDateCallback = (date: Date) => {
        setFilter({ ...filter, to: endOfDay(date) });
    };

    const handleStatusChange = (status: string) => {
        setFilter({ ...filter, status });
    };

    const downloadReport = async (
        pdfAsBase64String: string,
        rowData: Assessment,
    ) => {
        try {
            await downloadPdfReport(
                pdfAsBase64String,
                `${rowData.national_insurance_number}-Health_Information_Share_Report.pdf`,
            );
        } catch (error) {
            console.log(error);

            enqueueSnackbar(
                'There was an issue accessing this url, please try again.',
                {
                    variant: 'error',
                },
            );
        }
    };

    const onDownload = async (rowData: Assessment) => {
        setLoading(true);
        try {
            if (rowData.id) {
                const result = await infoShareReportPdf({
                    assessment_id: rowData.id,
                    namespace: 'CHDA',
                });
                const { pdfAsBase64String } = result.data;
                await downloadReport(pdfAsBase64String, rowData);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const getAssessments = async () => {
        try {
            const ref = collection(db, 'pip_assessments');
            const constraints: QueryConstraint[] = [
                where('created_at', '>=', filter.from),
                where('created_at', '<=', filter.to),
                orderBy('created_at', 'desc'),
                limit(300),
            ];

            if (!auth?.is_internal) {
                constraints.unshift(
                    where('customer_id', '==', auth?.customer_id ?? ''),
                );
            }

            if (filter.status !== 'all') {
                constraints.unshift(where('status', '==', filter.status));
            }

            if (filter.reviewed !== 'All') {
                constraints.unshift(
                    where('reviewed', '==', filter.reviewed === 'Reviewed'),
                );
            }
            const q = query(ref, ...constraints);
            const snapshots = await getDocs(q);
            let data: any[] = [];
            snapshots.forEach((snapshot) => {
                data.push({ ...snapshot.data(), id: snapshot.id });
            });
            setAssessmentList(data);
        } catch (e) {
            console.error(e);
            enqueueSnackbar('Error fetching assessments', {
                variant: 'error',
            });
            setLoading(false);
        }
    };

    const onReviewStatusToggled = async (
        rowData: Assessment,
        checked: boolean,
    ) => {
        setLoading(true);
        await reviewAssessment(rowData.id, checked, 'CHDA');
        await getAssessments();
        setLoading(false);
    };

    React.useEffect(() => {
        (async () => {
            if (!auth) {
                return;
            }
            setLoading(true);
            await getAssessments();
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, auth]);

    return (
        <>
            <Box sx={{ flex: 1, width: '100%' }}>
                <Box mt={4} mb={4}>
                    <Stack direction="row" spacing={2}>
                        <BasicDatePicker
                            label="Registered:From"
                            callback={handleFromDateCallback}
                        />
                        <BasicDatePicker
                            label="Registered:To"
                            callback={handleToDateCallback}
                        />
                        <BasicSelect
                            label="Status"
                            options={[
                                { value: 'completed', label: 'completed' },
                                { value: 'in-progress', label: 'in-progress' },
                                { value: 'closed', label: 'closed' },
                                { value: 'all', label: 'all' },
                            ]}
                            callback={handleStatusChange}
                            initialValue="completed"
                        />
                        <BasicSelect
                            label="Reviewed"
                            options={[
                                { value: 'Reviewed', label: 'Reviewed' },
                                {
                                    value: 'Not Reviewed',
                                    label: 'Not Reviewed',
                                },
                                { value: 'All', label: 'All' },
                            ]}
                            callback={(param) =>
                                setFilter({ ...filter, reviewed: param })
                            }
                            initialValue="Not Reviewed"
                        />
                    </Stack>
                </Box>
                <ThemeProvider theme={defaultMaterialTheme}>
                    <MaterialTable
                        title="Reports"
                        data={assessmentList}
                        columns={[
                            {
                                field: 'reviewed',
                                title: 'Reviewed',
                                render: (rowData) => (
                                    <Checkbox
                                        checked={rowData.reviewed ?? false}
                                        onChange={(event) => {
                                            onReviewStatusToggled(
                                                rowData,
                                                event.target.checked,
                                            );
                                        }}
                                    />
                                ),
                                headerStyle: {
                                    paddingLeft: '24px',
                                },
                                cellStyle: {
                                    paddingLeft: '24px',
                                },
                            },
                            { field: 'first_name', title: 'First Name' },
                            { field: 'last_name', title: 'Last Name' },
                            {
                                field: 'date_of_birth',
                                title: 'Date of Birth',
                                render: (rowData) => {
                                    return <DobHackComponent data={rowData} />;
                                },
                            },
                            {
                                field: 'national_insurance_number',
                                title: 'NINO',
                            },
                            {
                                field: 'created_at',
                                title: 'Session Start Date',
                                render: useCallback((row) => {
                                    return row.created_at
                                        .toDate()
                                        .toLocaleDateString();
                                }, []),
                            },
                            {
                                field: 'completed_at',
                                title: 'Session End Date',
                                render: useCallback((row) => {
                                    const updatedAt = row.completed_at;
                                    if (!updatedAt) {
                                        return '-';
                                    }
                                    return updatedAt
                                        .toDate()
                                        .toLocaleDateString();
                                }, []),
                            },
                            { field: 'status', title: 'Status' },
                            {
                                field: 'pdf_report',
                                title: 'Report',
                                render: useCallback((row: Assessment) => {
                                    if (row.status !== 'in-progress') {
                                        return (
                                            <IconButton
                                                onClick={() => onDownload(row)}
                                            >
                                                <PictureAsPdfSharp />
                                            </IconButton>
                                        );
                                    }
                                    return null;
                                    // eslint-disable-next-line
                                }, []),
                            },
                        ]}
                        options={{
                            searchFieldVariant: 'outlined',
                            pageSize: 20,
                            pageSizeOptions: [20, 40, 60],
                        }}
                    />
                </ThemeProvider>
                <TranscriptViewer
                    open={transcript.length > 1 && currentAssessment !== null}
                    patient={currentAssessment!}
                    transcript={transcript}
                    onClose={() => {
                        setTranscript([]);
                        setCurrentAssessment(null);
                    }}
                />
            </Box>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default AssessmentList;
