import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { CircularProgress } from '@mui/material';
import Toolbar from './Toolbar';
import { useAuth } from '../../hooks/useAuth';
import { AuditLogService } from '../../services/AuditLogService';
import { Assessment } from '../../types';

interface Props {
    file: string | null;
    showPrintButton?: boolean;
    patient: Assessment | null;
}

export default function PdfViewer(props: Props) {
    const { file, showPrintButton = true, patient } = props;

    const auth = useAuth();

    // Pagination
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    // Page scaling (zoom)
    const [scale, setScale] = useState(1);

    function onDocumentLoad(pdf: { numPages: number }) {
        setNumPages(pdf.numPages);
        setPageNumber(1);
    }

    function changePage(offset: number) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function changeScale(offset: number) {
        setScale((prevScale) => prevScale + offset);
    }

    function print() {
        if (!file) {
            return;
        }

        AuditLogService.addLog({
            action_type: 'READ',
            user: auth.user?.email || '',
            description: `Print PDF`,
            role_type: auth.is_admin ? 'admin' : 'super',
            additional_info: `PDF relating to ${patient?.id}`,
        });

        fetch(file)
            .then((res) => res.blob())
            .then((blob) => window.open(URL.createObjectURL(blob)));
    }

    return (
        <>
            <Toolbar
                numPages={numPages}
                pageNumber={pageNumber}
                nextPage={() => changePage(1)}
                previousPage={() => changePage(-1)}
                scale={scale}
                zoomIn={() => changeScale(0.25)}
                zoomOut={() => changeScale(-0.25)}
                print={showPrintButton ? print : null}
            />
            <div style={{ marginTop: 20 }}>
                <Document
                    file={file}
                    loading={<CircularProgress />}
                    onLoadSuccess={onDocumentLoad}
                >
                    <Page pageNumber={pageNumber} width={700} scale={scale} />
                </Document>
            </div>
        </>
    );
}
