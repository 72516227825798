import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { NamespaceType } from '../types';

const NamespaceControlled = ({
    children,
    namespace,
}: {
    children: JSX.Element;
    namespace: NamespaceType;
}) => {
    const authCtx = useAuth();
    const location = useLocation();

    const isSystemUser = authCtx.is_internal;

    return namespace === authCtx.namespace || isSystemUser ? (
        children
    ) : (
        <Navigate to="/dashboard" state={{ from: location }} replace />
    );
};

export default NamespaceControlled;
