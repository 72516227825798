import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import React from 'react';
import { db } from '../../config/FirebaseConfig';

interface Props {
    onChange: (customer: { customerId: string; customerName: string }) => void;
}

const CustomerSelector = (props: Props) => {
    const { onChange } = props;
    const [customerList, setCustomerList] = React.useState<Array<any>>([]);
    const [customer, setCustomer] = React.useState<string | null>(null);

    const handleChange = (event: SelectChangeEvent) => {
        const selectedCustomer = customerList.find(
            (customer) => customer.id === event.target.value,
        );
        setCustomer(selectedCustomer?.id ?? null);
        onChange({
            customerId: selectedCustomer?.id ?? '',
            customerName: selectedCustomer?.name ?? '',
        });
    };

    React.useEffect(() => {
        (async () => {
            const ref = collection(db, 'customers');
            const snapshot = await getDocs(ref);
            const temp: any[] = [];
            snapshot.forEach((doc) => {
                temp.push({ ...doc.data(), id: doc.id });
            });
            setCustomerList(temp);
        })();
    }, []);

    return (
        <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth>
                <InputLabel id="customer-simple-select-label">
                    Customer
                </InputLabel>
                <Select
                    labelId="customer-simple-select-label"
                    id="customer-simple-select"
                    value={customer ?? ''}
                    label="Customer"
                    onChange={handleChange}
                >
                    {customerList.map((cust) => (
                        <MenuItem
                            key={cust.id}
                            value={cust.id}
                            onClick={() => {
                                setCustomer(cust.id);
                            }}
                        >
                            {cust.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default CustomerSelector;
