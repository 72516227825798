import { Content } from 'pdfmake/interfaces';
import { pdfStylingVars } from '../../../pdfStyle';

interface Data {
    contactDetails?: string;
}

export default function ContactInformation(data?: Data): Content {
    // Style
    const { spacingLarge } = pdfStylingVars.spacing;

    return {
        margin: [0, spacingLarge, 0, 0],
        table: {
            widths: '*',
            body: [
                [
                    {
                        text: '00: Contact Information',
                        style: ['heading4', 'headerCell'],
                    }
                ],
                [
                    data?.contactDetails || 'NIL',
                ],
            ],
        },
        layout: 'default',
    };
}
